import React,{useState} from 'react'
import CourseCard from './BrowseCard';
import { NavLink } from 'react-router-dom';
import { SingleClick } from '../../../micros';
import { Cardcourses, SecondCardcourses } from '../../../micros/utilities/dataUtil';

const BrowseSection = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [filteredCourses, setFilteredCourses] = useState(Cardcourses);
    const [nextfilteredCourses, setNextFilteredCourses] = useState(SecondCardcourses);
    const [filterClicked, setFilterClicked] = useState(false);
    const [clickCount, setClickCount] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [continueClicked, setContinueClicked] = useState(false);

    const nextPage = () => {
      
      setContinueClicked(true);
      setPageCount(prevCount => prevCount + 1);
      if (pageCount % 2 === 0) {
        setContinueClicked(true);
       }
      else{
        setContinueClicked(false);
      }
    };


    const filterByType = (type) => {
      setClickCount(prevCount => prevCount + 1);
        if (clickCount % 2 === 0) {
            // Show original cards when clicked once
            setFilteredCourses(Cardcourses);
            setNextFilteredCourses(SecondCardcourses)
            setDropdownVisible(false)
            setSelectedFilter('');

          } else {
            // Apply filter logic when clicked twice
            // For example, filtering by 'Software Development' type
            const filtered = Cardcourses.filter(course => course.type === type);
            const filterednext = SecondCardcourses.filter(course => course.type === type);
            setFilteredCourses(filtered);
            setNextFilteredCourses(filterednext);
            setFilterClicked(true); // Set continueClicked to true when filter is applied
            setDropdownVisible(false)
            
            setSelectedFilter(type);

            
        }
      
  }


    
    const handleNavLinkClick = () => {
        // Toggle the dropdown visibility or perform other actions
        setDropdownVisible(!isDropdownVisible);
      };
  return (
    <div className='w-full relative  h-auto max-w-8xl overflow-x-hidden'>
        <div className='flex flex-col  md:flex-row md:m-6  m-0 gap-6 items-end justify-between md:px-14 px-5 '>
            <div className='flex flex-col text-purple-darker '>
                <h1 className='md:text-[3rem] text-[1.5rem] font-poppins-bold'>Browse Page</h1>
                <p className='font-poppins-semi-bold md:text-[1.2rem] text-[0.9rem]'>"Discover top courses led by experienced educators renowned for their expertise</p>
            </div>
            <div className=''>
                <div className='flex flex-row border-2 md:text-[1rem] text-sm text-nowrap justify-center text-purple-darker rounded-md border-purple-darker items-center p-2  w-fit h-[2.5rem] md:w-[11.25rem] md:h-[3.63rem]'
                onClick={handleNavLinkClick}> 
                   Select Course Type
                    <img src="/icons/Vector.svg" alt="Cancel Icon" className='w-6 h-6 p-1' />
                </div>
            
                </div >
                {/* Dropdown content */}
                {isDropdownVisible &&  (
                      <div className="absolute items-end top-auto md:my-[-10rem] z-[1]  my-40 right-0 mx-2  bg-white  border rounded shadow text-purple-darker">
                        {/* Dropdown links */}
                        <hr  className="border-purple-darker w-full"/>
                        <div className={`px-6 p-3 ${selectedFilter === 'softwaredevelopment' ? 'bg-purple-darker text-white' : 'bg-white'}`}  onClick={() => filterByType('softwaredevelopment')} >Software Development</div><hr  className="border-purple-darker w-full"/>
                        <div className={`px-6 p-3 ${selectedFilter === 'cybersecurity' ? 'bg-purple-darker text-white' : 'bg-white'}`} onClick={() => filterByType('cybersecurity')}>Cyber Security</div><hr  className="border-purple-darker w-full"/>
                        <div className={`px-6 p-3 ${selectedFilter === 'emergingtechnologies' ? 'bg-purple-darker text-white' : 'bg-white'}`} onClick={() => filterByType('emergingtechnologies')}>Emerging Technologies</div><hr  className="border-purple-darker w-full" />
                       
                      </div>
                    )}
                    </div>
                    <div className=''>
                      {continueClicked ? (
                    // Render the replacement component based on the next button clicked
                    // For now, let's render the CourseCard component with allCards state
                    <CourseCard courses={filterClicked ? nextfilteredCourses : SecondCardcourses} />
                ) : (
                    // Render the normal cards if "Continue" has not been clicked
                    <CourseCard courses={filterClicked ? filteredCourses : Cardcourses}/>
                )}
                    
                     </div>
                                <div className='flex items-center justify-center pt-10 pb-10'>
                                  <SingleClick
                                      buttonClass="10"
                                      buttonText={continueClicked ? "Back":"Next"}
                                      buttonType="submit"
                                      onClick={nextPage}
                                      
                                    />
                                    </div>
    </div>
  );
};
export default BrowseSection