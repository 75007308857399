import React from 'react'
import { ServiceFeatures } from '../../../micros/index'

function WebDevService() {
  const services = [
    
    {
        title: ' Business Package',
        price: '559',
        colorFill: 'purple-darker',
        mainFeatures: [
            'Responsive web design',
            'Up to 8 pages ',
            'Customized design',
            'Basic Analytics setup'
        ],
        extraFeatures: [
            'Blog integration',
            'Basic SEO'
        ]
       
    },
    {
        title: 'Standard Package',
        colorFill: 'purple-darker',
        price: '749',
        mainFeatures: [
            'Responsive web design',
            'Upto 12 pages',
            'Customized design',
            'Standard Analytics setup'
        ],
        extraFeatures: [
            'Blog integration',
            'Standard SEO and Content Management System',
            'User Training  and  Custom chatbot'
        ]
       
    },
    {
        title: 'Premium Package',
        price: '1050',
        colorFill: 'purple-darker',
        mainFeatures: [
            'Responsive web design',
            'Unlimited pages and Custom Design',
            'Advance Analytics setup',
            'Advanced SEO and Content Management System'
        ],
        extraFeatures: [
            'Secure payment integration',
            'Maintenance and Support and Regular software updates and security patches',
            'User Training and Custom Chatbot'
        ]
       
    },
//     {
//       title: 'E-Commerce Package',
//       colorFill: 'purple-darker',
//       price: '1750',
//       mainFeatures: [
//           'Custom Design with emphasis to UX',
//           'Responsive design for mobile devices',
//           'Product catalogue setup (up to 500 products)',
//           'Shopping cart and secure checkouts and payment gateway integration'
//       ],
//       extraFeatures: [
//           'Product search and filtering functionality',
//           'Advance SEO Optimization and Content Management System',
//           'Analytics setup and Custom chatbot and Maintenance'
//       ]
     
//   },

    ]

    const services2 = [
    
        {
            title: 'E-Commerce Package',
            colorFill: 'purple-darker',
            price: '1750',
            mainFeatures: [
                'Custom Design with emphasis to UX',
                'Responsive design for mobile devices',
                'Product catalogue setup (up to 500 products)',
                'Shopping cart and secure checkouts and payment gateway integration'
            ],
            extraFeatures: [
                'Product search and filtering functionality',
                'Advance SEO Optimization and Content Management System',
                'Analytics setup and Custom chatbot and Maintenance'
            ]
           
        },
        ]
    return (
    <>
        <div id='learn_more' className=' text-center flex flex-col justify-center items-center bg-purple-f  pb-[4rem] sm:pb-[6rem] xl:pb-[10rem] w-full'>
            <h1 className='font-poppins-bold text-3xl sm:text-4xl xl:text-5xl pt-[5rem] lg:pt-[9rem] text-purple-darker'>Discover the perfect solution for your needs.</h1>
            <div className='w-[20rem] xl:w-[35rem] h-2 bg-purple-darker mt-4 mb-[3rem] lg:mb-[8.5rem]'></div>
            <div className='flex justify-start items-center px-4'>
                <div className='flex gap-[5rem] items-center  justify-center  md:flex-grow flex-col  '>
                    <div className='flex gap-[5rem] items-center  justify-center md:flex-row flex-col'>
                    {services.map((service, index) => (
                      <ServiceFeatures {...service} key={index} />

                        )
                    )}
                    </div>

                    {services2.map((service, index) => (
                      <ServiceFeatures {...service} key={index} />

                        )
                    )}
                </div>
        
            </div>
            
        </div>
    </>
    )
}

export default WebDevService