import React from "react";
import { TestimonialsPart } from "../../components/micros/index.jsx";
import { Statistics } from "../../components/micros/index.jsx";

import {
  Hero,
  AboutUs,
  CourseSection,
  ContactUs,
  Service,
} from "../../components/index.jsx";

function Home() {
  return (
    <div className="w-full h-full">
      <Hero />
      <Service />
      <AboutUs />
      <CourseSection />
      {/*<Statistics />*/}
      <TestimonialsPart />
    </div>
  );
}

export default Home;
