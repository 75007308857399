import React from "react";
import { ServicesHero } from "../../../components/index";
import { WhyWeb, WebDevService } from "../../../components/major/index";
import { BannerSection } from "../../../components/micros/index";

const WebDev = () => {
  return (
    <>
      <ServicesHero
        serviceTitle="Web Development"
        serviceTitleImg="/icons/webdevTittle.png"
        serviceDescription1="We create websites and applications that captivate audiences and produce results, from basic brochures to advanced e-commerce platforms."
        serviceDescription2="Our commitment to excellence, attention to detail, and dedication to client satisfaction set us apart as a trusted partner in your online journey."
        serviceImg="/icons/web-devImg.svg"
      />

      <WhyWeb />
      <div className="flex items-center justify-center ">
        <BannerSection
          backgroundImage="/icons/webBanner1.png"
          desc="Explore our range of web development packages below and take the first step towards elevating your online presence. We look forward to bringing your digital dreams to reality."
          btnText="Book a Meeting"
        />
      </div>

      <WebDevService />
    </>
  );
};

export default WebDev;
