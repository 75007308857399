import React, { useState, useEffect } from "react";
import { Input } from "../../micros";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { credentials } from "../../../auth";
import { config } from "../../../auth";
import MessageTxt from "../../major/messagesent/MessageTxt";

const ApplyPopUp = ({ onClose }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [LinkedInLink, setLinkedInLink] = useState("");
  const [GitHubLink, setGitHubLink] = useState("");
  const [whyYou, setWhyYou] = useState("");
  const [disputeHandling, setDisputeHandling] = useState("");
  const [resume, setResume] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resumeName, setResumeName] = useState("Upload CV/Resume (PDF)");
  const [formError, setFormError] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    const roleFromURL = window.location.pathname.split("/").pop();
    const roleMapping = {
      FrontendDev: "frontend-developer",
      BackendDev: "backend-developer",
      Designer: "ui-ux-designer",
      CloudSecurityArchitect: "cloud-security-architect",
      CybersecurityRiskAnalyst: "cybersecurity-risk-analyst",
      DevOpsEngineer: "devops-engineer",
      MachineLearningEngineer: "machine-learning-engineer",
      MobileAppsDeveloper: "mobile-apps-developer",
      NetworkSecurityAnalyst: "network-security-analyst",
      PenetrationTester: "penetration-tester",
    };

    setRole(roleMapping[roleFromURL] || "");
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setResume(file);
        setResumeName(file.name);
      } else {
        alert("Please select a PDF file");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !fullName ||
      !email ||
      !role ||
      !phoneNumber ||
      !resume ||
      !whyYou ||
      !GitHubLink ||
      !LinkedInLink ||
      !disputeHandling
    ) {
      setFormError(true);
      return;
    }

    // Create FormData
    const formData = new FormData();
    formData.append("full_name", fullName);
    formData.append("email", email);
    formData.append("role", role);
    formData.append("linkedin", LinkedInLink);
    formData.append("github", GitHubLink);
    formData.append("reason", whyYou);
    formData.append("handle_dispute", disputeHandling);
    formData.append("phone_number", phoneNumber);
    formData.append("resume", resume);
    console.log(formData);
    setFormSubmit(!formSubmit);
    try {
      const response = await fetch(`${config.baseURL}/careers/internships/`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);

      // Reset form fields
      setFullName("");
      setEmail("");
      setRole("");
      setPhoneNumber("");
      setLinkedInLink("");
      setGitHubLink("");
      setWhyYou("");
      setDisputeHandling("");
      setResume(null);
      setResumeName("Upload CV/Resume (PDF)");
      setFormError(false);
      onClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {formSubmit ? (
        <div className="items-center justify-center flex">
          <MessageTxt onClose={onClose} />
        </div>
      ) : (
        <div className="backdrop-filter backdrop-blur fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-md w-full relative">
            <button
              onClick={onClose}
              className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-600"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="p-6 h-[35rem] overflow-auto">
              <h2 className="text-3xl font-bold mb-2 text-center">APPLY</h2>
              <h2 className="text-1xl text-gray-500 mb-10 text-center">
                Let's connect and work together with just one click
              </h2>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Full Name
                  </label>
                  <Input
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={setFullName}
                    inputClass="3"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={setEmail}
                    inputClass="3"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Role
                  </label>
                  <input
                    type="text"
                    placeholder="Role"
                    value={role}
                    className="w-full rounded-sm border border-purple-darker border-opacity-10 bg-purple-f p-2 pl-1.5 sm:pl-2.5 focus:outline-none text-sm"
                    readOnly
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <PhoneInput
                    international
                    defaultCountry="KE"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className="border py-3 px-4 w-full rounded focus:outline-none "
                    style={{ backgroundColor: "#FFF5FE" }}
                    required
                  />
                </div>
                <div className="mb-4 flex items-center">
                  <div
                    className="flex items-center px-4 py-3 bg-[rgb(255,245,254)] rounded border border-[rgb(239,239,244)] w-full"
                    onClick={(e) => {
                      e.stopPropagation();
                      document.getElementById("resume").click();
                    }}
                  >
                    <span className="text-sm text-gray-500 flex-grow">
                      {resumeName}
                    </span>
                    <img
                      src="/Upload_icon.svg"
                      alt="Upload"
                      className="h-6 w-6 text-blue-500 ml-2"
                    />
                  </div>
                  <input
                    id="resume"
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                    className="hidden"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    GitHub
                  </label>
                  <Input
                    type="github"
                    placeholder="GitHub URL"
                    value={GitHubLink}
                    onChange={setGitHubLink}
                    inputClass="3"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    LinkedIn
                  </label>
                  <Input
                    type="linkedin"
                    placeholder="LinkedIn URL"
                    value={LinkedInLink}
                    onChange={setLinkedInLink}
                    inputClass="3"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Why should we choose you?
                  </label>
                  <Input
                    type="textarea1"
                    placeholder="Why should we choose you?"
                    value={whyYou}
                    onChange={setWhyYou}
                    inputClass="4"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    How do you handle disputes?
                  </label>
                  <Input
                    type="textarea2"
                    placeholder="How do you handle disputes?"
                    value={disputeHandling}
                    onChange={setDisputeHandling}
                    inputClass="4"
                  />
                </div>

                {formError && (
                  <p className="text-red-500 text-sm mb-4">
                    Please fill in all required fields.
                  </p>
                )}

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="px-6 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyPopUp;
