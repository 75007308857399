import React, { useState } from "react";

function Header({ activeTab, handleClickTab, tabs }) {
  return (
    <div className="px-2 md:px-24 w-[100vw] sm:w-full">
      <div className="bg-[#E6E6E6] w-full overflow-auto rounded-lg flex justify-between gap-4  p-[4px]">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-[10px] py-2 rounded-[10px] text-sm text-[#590050] font-poppins min-w-[150px] max-w-[330px] min-h-[60px] ${
              activeTab === tab ? "bg-white" : "bg-transparent"
            }`}
            // onClick={() => setActiveTab(tab)}
            onClick={() => handleClickTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Header;
