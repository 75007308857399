import React from "react";
import Readytojoin from "../../major/wHTaCampus/Joinus/Readytojoin";
import Whocanjoin from "../../major/wHTaCampus/Joinus/Whocanjoin";

function JoinusSection() {
  return (
    <div>
      <Readytojoin />
      <Whocanjoin />
    </div>
  );
}

export default JoinusSection;
