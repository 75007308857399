import React from "react";

function Details({ activeServiceDetails }) {
  return (
    <div className="flex flex-wrap gap-4 justify-center pt-10 md:px-2 w-full">
      {activeServiceDetails.map((detail, index) => (
        <div
          key={index}
          className="w-full md:w-[26rem] flex flex-col items-center px-14 pb-14 gap-4 rounded-[49.16px] text-[#590050] border border-gray-200 mb-6 shadow-lg"
        >
          <hr className="w-[60%] h-2 bg-[#590050] mb-2" />
          <div className="w-[40px] h-[40px] flex justify-center items-center overflow-hidden">
            <img
              src={detail.image}
              alt={detail.name}
              className="h-full w-full object-cover"
            />
          </div>
          <h2 className="text-md font-semibold text-center mb-2">
            {detail.name}
          </h2>
          <div className="flex flex-col flex-grow items-center gap-4">
            {detail.detailsDescription.map((text, idx) => (
              <p
                key={idx}
                className="text-md text-center text-sm text-[#05195F]"
              >
                {text}
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Details;
