import React from 'react'


function EventCard(event) {
    const handleClick = (e) => {
        console.log("hi "+ e)
    }
  return (
    <>
        <div onClick={() => {handleClick(event.text)}} className='p-2 group w-auto lg:max-w-[51rem] h-auto lg:h-[25rem] relative flex justify-center items-center cursor-pointer'>
            <img src={event.img} alt={event.text} className='w-full h-full'/>
            <h2 className='font-poppins-bold text-4xl  xl:text-6xl absolute text-white-a group-hover:hidden transition-all duration-200'>{event.text}</h2>
            <div className='hidden group-hover:block absolute w-full h-full p-6 '>
                <h3 className='font-poppins-bold text-2xl sm:text-3xl xl:text-4xl  text-white-a'>
                    {event.desc.title}
                </h3>
                <div className='flex flex-col mt-[20%] xl:mt-[30%] font-poppins text-xs sm:text-base lg:text-2xl text-white-a'>
                    <div className=' flex flex-row justify-between'>
                        <h5>Date: {event.desc.date}</h5>
                        <h5>Location: {event.desc.location}</h5>
                    </div>
                    <div className=' flex flex-row justify-between mt-4'>
                        <h5>{event.desc.time}</h5>
                        <h5>Ticket: ksh. {event.desc.ticket}</h5>
                    </div>
                </div>
            
            </div>
          

        </div>
    
    </>
  )
}

export default EventCard