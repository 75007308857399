export const Cybersecurity = [
    {
      image: "compliance icon-new.svg",
      title: "HIPAA compliance",
      description: "we ensure your systems comply with HIPAA regulations to protect patient privacy",
    },
    {
      image: "Risk assessment.png",
      title: "Risk Assessment",
      description: "we identify and assess potential security risks to safeguard patient data",
    },
    {
      image: "Penetration testing.png",
      title: "Penetration Testing",
      description: "we test your system to uncover vulnerabilities and strengthen defenses.",
    },
    {
      image: "security.svg",
      title: "Patient data protection",
      description: "we implement measures to secure sensitive patient information from unauthorized access",
    },
    {
      image: "icons/Digital forensics.png",
      title: "Digital Forensics",
      description: "we analyze security breaches to understand the cause and prevent future incidents",
    },
    {
      image: "Employee training.png",
      title: "Employee Training",
      description: "we train healthcare staff on cybersecurity best practices and compliance requirements.",
    },
];

export const technologyCybersecurity = [
  {
    image: "compliance icon-new.svg",
    title: "DevSecops Integration",
    description: "we integrate security practices into your Devops processes to ensure secure software development.",
  },
  {
    image: "Risk assessment.png",
    title: "Risk Assessment",
    description: "we identify and assess potential security risks to safeguard patient data",
  },
  {
    image: "Penetration testing.png",
    title: "Penetration Testing",
    description: "we test your system to uncover vulnerabilities and strengthen defenses.",
  },
  {
    image: "security.svg",
    title: "Threat Monitoring",
    description: "we continously monitor for threats and respond swiftly to security incidents",
  },
  
  
  {
    image: "icons/Digital forensics.png",
    title: "Digital Forensics",
    description: "we analyze security breaches to understand the cause and prevent future incidents",
  },
  {
    image: "Employee training.png",
    title: "Employee Training",
    description: "we provide training on the latest Cybersecurity threats and the best practices for technology professionals.",
  },
];

export const financeCybersecurity = [
  {
    image: "compliance icon-new.svg",
    title: "SOX compliance",
    description: "we ensure your systems comply with SOX and GDPR financial regulatory standards.",
  },
  {
    image: "security.svg",
    title: "Risk Assessment",
    description: "we evaluate and mitigate security risks to protect financial data",
  },
  {
    image: "Penetration testing.png",
    title: "Penetration Testing",
    description: "we test your system to uncover vulnerabilities and strengthen defenses.",
  },
  {
    image: "Risk assessment.png",
    title: "Financial Data Security",
    description: "we implement measures to secure sensitive financial information.",
  },
  
  
  {
    image: "icons/Digital forensics.png",
    title: "Digital Forensics",
    description: "we analyze security breaches to understand the cause and prevent future incidents",
  },
  {
    image: "Employee training.png",
    title: "Employee Training",
    description: "we train employees on best practices for protecting financial data and complying with regulations.",
  },
];
  
export const retailCybersecurity = [
  {
    image: "compliance icon-new.svg",
    title: "PCI DSS compliance",
    description: "we ensure secure transactions and compliance with PCI DSS standards.",
  },
  
  
  {
    image: "security.svg",
    title: "Risk Assessment",
    description: "we evaluate and mitigate security risks to protect financial data",
  },

  
  {
    image: "Penetration testing.png",
    title: "Penetration Testing",
    description: "we identify vulnerabilities in your systems before attackers do by simulating real world attacks"
  },
  {
    image: "security.svg",
    title: "Threat Monitoring",
    description: "we continously monitor for threats and respond swiftly to security incidents",
  },
  {
    image: "icons/Digital forensics.png",
    title: "Digital Forensics",
    description: "we analyze security breaches to understand the cause and prevent future incidents",
  },
  {
    image: "Employee training.png",
    title: "Employee Training",
    description: "we provide training on the latest Cybersecurity threats and the best practices for technology professionals.",
  },
];
  