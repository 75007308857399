import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "Figma",
    "Wire Framing",
    "Prototyping",
    "User Research",
    "HTML",
    "CSS",
    "JavaScript",
    "User Personas",
    "User Journey Mapping",
    "Usability Testing",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Empathy",
    "Collaboration",
    "Time management",
    "Attention to detail",
    "Adaptability",
    "Creativity",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Conducting user research to understand user needs and behaviors.",
    "Creating wireframes, prototypes, and mockups for digital products.",
    "Crafting visually appealing designs that enhance user experience.",
    "Designing intuitive and engaging interactions for users.",
    "Conducting usability testing to gather feedback and improve designs.",
    "Establishing information architecture to organize content effectively.",
    "Ensuring designs adhere to accessibility standards for inclusive design.",
    "Collaborating effectively with cross-functional teams.",
    "Managing design systems to maintain consistency across products.",
    "Continuously learning and improving design skills and methodologies.",
  ];

  const qualifications = [
    "Strong understanding of user interface design principles and best practices.",
    "Experience with wireframing, prototyping, and mockup tools.",
    "Familiarity with HTML, CSS, and JavaScript for frontend implementation.",
    "Bachelor's degree in Graphic Design, Human-Computer Interaction, or related field preferred.",
    "Previous experience as a UI/UX designer with a portfolio of projects demonstrating design skills and process.",
    "Certifications or additional training in UI/UX design are beneficial.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"UI/UX Designer"}
          Description={
            "We are currently seeking a talented and innovative UI/UX Designer to join our dynamic team. As a UI/UX Designer, you will play a pivotal role in shaping the user experience of our digital products. The ideal candidate will have a strong proficiency in leading design tools, particularly Figma, and a solid understanding of user interface design principles and best practices."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
