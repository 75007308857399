import React from "react";
import AdvantagesComponent from "../../../components/sections/industries/healthcare/advantages/Advantages";
import SoftwareComponent from "../../../components/sections/industries/healthcare/advantages/software";
import { Cybersecurity } from "../../../components/micros/utilities/dataUtilCybersecurity";
import CybersecurityComponent from "../../../components/sections/industries/healthcare/advantages/cybersecurity";

import IndustriesHerosection from "../../../components/sections/industries/healthcare/herosection/IndustriesHero";
import { Software } from "../../../components/micros/utilities/dataUtilSoftware";




function HealthCare() {
  return (

    <div className="w-full h-full " >
     <IndustriesHerosection maintitle={'Elevating Healthcare with Comprehensive Services at wHTa Networks'} backgroundimage={'/icons/Forensics.png'} title={'HealthCare'}/>
    <CybersecurityComponent data={Cybersecurity}/>
     <SoftwareComponent data={Software}/>

      <AdvantagesComponent/>

    </div>
  );
}

export default HealthCare;
 