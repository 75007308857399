// src/mainstore.js
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from './authSlice';
import { studentApi } from './studentApi';
import { instructorApi } from './instructorApi';
import { adminApi } from './adminApi';
import { signInApi } from './signupApi';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        [studentApi.reducerPath]: studentApi.reducer, // Add the API reducer
        [instructorApi.reducerPath]: instructorApi.reducer, // Add the API reducer
        [adminApi.reducerPath]: adminApi.reducer, // Add the API reducer
        [signInApi.reducerPath]:signInApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
        // Add the API middlewares
        .concat(studentApi.middleware) 
        .concat(adminApi.middleware)
        .concat(instructorApi.middleware)
        .concat(signInApi.middleware), 
});

setupListeners(store.dispatch);


// export default store;
