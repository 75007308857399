import React, { useState } from 'react';


function CybersecurityComponent({data}) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const services = data;

  
  const splitIntoGroups = (array) => {
    const groups = [];
    for (let i = 0; i < array.length; i += 3) {
      groups.push(array.slice(i, i + 3));
    }
    return groups;
  };

 
  const splitIntoRows = (array) => {
    const rows = [];
    for (let i = 0; i < array.length; i += 1) {
      rows.push(array.slice(i, i + 1));
    }
    return rows;
  };

  const handleCardHover = (index) => {
    setHoveredIndex(index);
  };

  const getDescriptionAlignment = (description) => {
    return description.length > 100 ? 'justify-start' : 'justify-center';
  };

  return (
    <>
      <div className='text-center flex flex-col justify-center items-center pb-[4rem] sm:pb-[6rem] xl:pb-[10rem]' style={{ backgroundColor: "rgba(242, 225, 241, 0.7)" }}>
        <h1 className='font-poppins-bold text-xl sm:text-4xl xl:text-5xl pt-[5rem] lg:pt-[9rem] text-purple-darker'>
          Cybersecurity Solutions Process with wHTa Networks <span className='whitespace-nowrap'>for your Organization</span>
        </h1>
        <div className='w-[15rem] md:w-[30rem] h-2 bg-purple-darker mt-4 mb-[3rem] lg:mb-[5.5rem]'></div>
        <div className='flex justify-center  items-center md:px-4 px-auto w-full'>
         
          {splitIntoRows(splitIntoGroups(services)).map((row, rowIndex) => (
            <div key={rowIndex} className='flex  lg:gap-[5rem] items-center justify-start'>
              {row.map((group, groupIndex) => (
                <div key={groupIndex} className='flex flex-col items-center'>
                  {group.map((service, serviceIndex) => (
                    <div
                      key={serviceIndex}
                      className='advantage-item md:w-[450px] md:h-[250px] w-36 h-auto md:p-4 p-2 rounded-3xl shadow-md flex flex-col place-items-stretch'
                      style={{
                        
                        backgroundColor: "white",
                        margin: '10px',
                        
                        position: 'relative', 
                        zIndex: hoveredIndex === `${rowIndex}-${groupIndex}-${serviceIndex}` ? 10 : 1, // Increase z-index if hovered
                        transform: hoveredIndex === `${rowIndex}-${groupIndex}-${serviceIndex}` ? 'scale(1.05)' : 'scale(1)', // Add scaling for visual feedback
                        transition: 'transform 0.3s, z-index 0.3s',
                      }}
                      onMouseEnter={() => handleCardHover(`${rowIndex}-${groupIndex}-${serviceIndex}`)}
                      onMouseLeave={() => handleCardHover(null)}
                    >
                      <div className="h-1/2 flex justify-center items-center">
                        <div className="rounded-full justify-center items-center overflow-hidden md:w-[100px] md:h-[100px]" style={{  backgroundColor: 'rgba(142, 12, 129, 1)' }}>
                          <img src={service.image} alt={service.title} className="h-auto w-auto align-middle p-5 justify-center items-center flex" />
                        </div>
                      </div>
                      <h2 className='md:text-[2rem] text-lg font-semibold text-center mb-2 py-3' style={{ color: "black" }}>
                        {service.title}
                      </h2>
                      <div className={`flex flex-grow items-center ${getDescriptionAlignment(service.description)}`}>
                        <p className='text-md text-center' style={{ fontSize: '1rem', fontWeight: 'semibold' }}>
                          {service.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CybersecurityComponent;









