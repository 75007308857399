import React from "react";

function RegSuccess({ closePopup }) {
  return (
    <div className="relative bg-white mt-18 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[] md:w-[25rem] md:h-[] h-auto md:max-w-[28rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[]  rounded-lg max-h-[80vh]    overflow-y-auto">
      <div className="relative p-6 flex flex-col items-center justify-center gap-2 font-poppins">
        <p
          className="absolute right-0 top-0 cursor-pointer"
          onClick={closePopup}
        >
          <img src="/close.svg" alt="" className="size-4" />
        </p>
        <img src="/clap-icon.svg" alt="" className="size-24" />
        <p className="text-xl font-poppins-semi-bold text-[#05195F] text-center mb-2">
          Registration Successful
        </p>
        <p className="text-center">
          Our team at wHTa Networks Campus Hub will contact you within 24 hours.
        </p>
      </div>
    </div>
  );
}

export default RegSuccess;
