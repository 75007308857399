import React from 'react'
import { ServiceCard } from '../../../micros/index'

function ForensicService() {
    const services = [
        {
            title: 'Incident Identification and Triage',
            description: "Simulating social engineering attacks, such as phishing and pretexting, to assess your employees' awareness and response to potential threats."
        },
        {
            title: 'Forensic Data Collection and Preservation',
            description: 'Collecting and preserving digital evidence from various sources, including computers, servers, mobile devices, and network logs.'
        },
        {
            title: 'Forensic Analysis and Investigation',
            description: " Conducting in-depth analysis of digital evidence to identify the cause of security breaches, unauthorized access, data theft, or other cyber incidents."
        },
        {
            title:' Incident Containment and Remediation',
            description: "We take action to contain the incident, minimize damage, and restore systems and services to normal."
        },
        {
            title:"Post-Incident Reporting and Recommendations",
            description: "Documenting findings, lessons learned, and recommendations for improving your organization's cybersecurity posture to prevent future incidents."
        }


    ]
  return (
    <>
        <div  id="learn_more"className=' text-center flex flex-col justify-center items-center bg-purple-f pb-[4rem] sm:pb-[6rem] xl:pb-[10rem]'>
            <h1 className='font-poppins-bold text-3xl sm:text-4xl xl:text-5xl pt-[5rem] lg:pt-[9rem] text-purple-darker'>Our Digital Forensics and Incident Response Services</h1>
            <div className='w-[20rem] xl:w-[35rem] h-2 bg-purple-darker mt-4 mb-[3rem] lg:mb-[8.5rem]'></div>
            <div className='flex justify-center items-center px-4 '>
                <div className='flex gap-[3rem] lg:gap-[5rem] items-center justify-center  md:flex-row flex-col flex-wrap'>
                    {services.map((service, index) => (
                       <ServiceCard {...service} key={index} cardClass='2' tittleClass='1'/>

                        )
                    )}
                </div>
         
            </div>
            
        </div>
    </>
  )
}

export default ForensicService