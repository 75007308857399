import React from "react";
import { WhyChooseUs } from "../../../micros/index";

const Whyus = () => {
  const whyusData = [
    {
      title: "IT Expertise",
      description:
        "We're ready to turn your ideas into cutting-edge solutions with our deep IT Expertise.",
      icon: "icons/brain.svg",
    },
    {
      title: "Transparency",
      description:
        "We openly share our processes, providing insight into client collaboration",
      icon: "icons/softdev.svg",
    },
    {
      title: "24/7 IT Support",
      description:
        "Our team ensures constant system operation and proactive monitoring 24/7.",
      icon: "icons/headset1.svg",
    },
    {
      title: "Pricing",
      description:
        "Exceptional Quality, Friendly Prices - Your Ideal Software Development Partner",
      icon: "icons/money.svg",
    },
  ];

  return (
    <div className="w-full max-w-8xl">
      <h2 className="text-black-a text-4xl sm:text-5xl font-poppins-bold text-center py-10 sm:py-20 sm:pt-32">
        Why choose us
      </h2>
      <div className="flex flex-wrap items-center justify-center lg:justify-between gap-4 py-20">
        {whyusData.map((whyus, index) => (
          <WhyChooseUs {...whyus} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Whyus;
