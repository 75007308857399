import React, { useState } from "react";
import suitcase from "/icons/suitcase.svg";
import coin from "/icons/coin.svg";
import { SingleClick } from "../../micros/index";

import { Jobs } from "../../micros/utilities/dataUtil";
import { useNavigate } from "react-router-dom";

function Jobcards({ allJobs }) {
  let nav = useNavigate();

  const handleApplication = (route) => {
    console.log(route);
    nav(route);
  };

  // Routes for each job
  const jobRoutes = [
    "/FrontendDev",
    "/Designer",
    "/BackendDev",
    "/DevOpsEngineer",
    "/NetworkSecurityAnalyst",
    "/CloudSecurityArchitect",
    "/PenetrationTester",
    "/CybersecurityRiskAnalyst",
    "/MachineLearningEngineer",
    "/MobileAppsDeveloper",
  ];


  return (
    <>
      <div className="max-w-screen-xl mx-auto">
        <div className="">
          <div className="">
            
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-16 mx-5">
          {Jobs.map((job, index) => (
            <div key={index}>
              <div className="bg-white-a p-4 rounded-md border border-gray-300">
                <div className="flex items-center mb-2">
                  <img
                    src={suitcase}
                    alt="Suitcase Icon"
                    className="w-4 h-4 mr-2"
                  />
                  <h2 className="text-lg font-semibold">{job.title}</h2>
                  <img
                    src={coin}
                    alt="Coin Icon"
                    className="w-4 h-4 mr-2 ml-auto"
                  />
                  <h2 className="text-lg">Internship</h2>
                </div>
                <div className="h-4"></div>
                <div className="flex justify-between">
                  <SingleClick
                    buttonClass="12"
                    buttonText={job.type}
                    buttonType="button"
                  />
                  {/* Pass the corresponding route for each job */}
                  <SingleClick

                    buttonClass="14"
                    buttonText="Apply"
                    buttonType="button"
                    onClick={() => handleApplication(jobRoutes[index])}

                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Jobcards;
