import React, { useState } from "react";


function MessageTxt({onClose,formState}) {
  const [modal, setModal] = useState(true);
  const [formSubmit, setFormSubmit] = useState(formState);
  
  
  

  const toggleModal = () => {
    setModal(!modal);
    setFormSubmit(false)
    formState(false)
    
  };

  if (modal) {
    document.body.classList.add('overflow-y-hidden')
  } else {
    document.body.classList.remove('overflow-y-hidden')
  }

  return (
    <>
      {/* <button onClick={toggleModal} className="btn-modal px-4 py-2 block mx-auto mt-24 text-lg border-2 border-black">
        Submit
      </button> */}
      {modal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
          <div onClick={toggleModal} className="fixed inset-0 w-screen h-screen">
            <div className="box sm:w-2/3 lg:w-1/2 xl:w-2/5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 leading-loose bg-white py-3.5 px-3.5 shadow shadow-slate-500 rounded max-w-600 md:min-w-300 md:w-full md:m-auto w-64 h-auto md:h-80 text-center">
              <h2 className="text-xl md:text-4xl font-poppins-bold mt-6 tracking-wider">
                Message Sent!
              </h2>
              <p className="font-poppins text-md mt-4">
                Our team shall contact you soon
              </p>
              <img
                src="/icons/check.svg"
                alt="check img"
                className="w-15 h-16 md:w-15 md:h-16 mx-auto mt-6"
              />
              <button
                className="absolute py-2 px-1.5 top-0 right-4 text-xl text-slate-400"
                onClick={onClose}
                

              >
                x
              </button>
            </div>
            <div></div>
          </div>
        </div>
      )}
    
    </>
  );
}
export default MessageTxt;
