import React, { useState } from "react";
import { SingleClick } from "../../micros";
import {
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  RegSuccess,
} from "../../major/index";

function HeroSection() {
  const [formData, setFormData] = useState({
    full_name: "",
    student_email: "",
    phone_number: "",
    registration_number: "",
    course_enrolled: "",
    institution: "",
    faculty_school: "",
    department: "",
    year_enrolled: "",
    graduation_year: "",
    student_id: "",
    selfie_with_id: "",
    pitch_description: "",
    unique_elements: "",
    target_audience: "",
    potential_obstacles: "",
    immediate_actions: "",
    paymentSuccess: false,
  });

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  // const [isPaySuccess, setIsPaySuccess] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
  };
  //   handleRegister
  const handleClickRegister = () => {
    setCurrentStep(1);
    openPopup();
  };
  // const handleRegister = () => {
  //   setIsPaySuccess(true);
  //   if (isPaySuccess) {
  //     nextStep();
  //     openPopup();
  //   }
  // };

  const handleRegister = () => {
    // Set payment success in form data
    setFormData({
      ...formData,
      paymentSuccess: true,
    });
    // alert(formData);
    // Move to the next step
    if (formData.paymentSuccess) {
      nextStep();
      openPopup();
    }
  };

  // const handlePaySuccess = () => {
  // setIsPaySuccess(true);
  // };
  const nextStep = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateFormData = (newData) => {
    setFormData({
      ...formData,
      ...newData,
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            onNext={nextStep}
            closePopup={closePopup}
            updateFormData={updateFormData}
            formData={formData}
          />
        );
      case 2:
        return (
          <StepTwo
            onNext={nextStep}
            closePopup={closePopup}
            updateFormData={updateFormData}
            formData={formData}
          />
        );
      case 3:
        return (
          <StepThree
            onNext={nextStep}
            closePopup={closePopup}
            updateFormData={updateFormData}
            formData={formData}
          />
        );
      case 4:
        return (
          <StepFour
            onNext={handleRegister}
            closePopup={closePopup}
            updateFormData={updateFormData}
            formData={formData}
          />
        );
      case 5:
        return <RegSuccess closePopup={closePopup} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-auto  w-full p-auto overflow-y-hidden">
      {/* Content for the second section  */}
      <div className=" bg-white flex justify-center">
        <div
          className="second-section flex-1  flex flex-col md:flex-row max-w-8xl py-8
        items-center justify-between font-poppinsd sm:text-sm lg:text-[1.11rem] h-auto"
        >
          <div className="w-5/6 sm:w-3/4 md:w-1/2 mb-4 lg:mb-8 py-4 pl-0 md:pl-20">
            <h1 className="font-poppins-semi-bold text-4xl text-[#9B1F96]">
              wHTa Campus Hub
            </h1>
            <h2 className="my-2 text-2xl font-poppins text-[#F500CE]">
              Where Visionaries Create The Future
            </h2>
            <p className="mb- w-full text-lg">
              Do you have a bright idea that's just waiting to shine? Whether
              it's an innovative project, a solution to a pressing problem, or a
              creative concept that could make a difference, we're excited to
              hear about it!{" "}
            </p>
            <p className="mb-4 text-lg">
              At the wHTa Campus Hub, we’re all about fostering creativity,
              innovation, and positive change. We're looking for passionate
              students ready to take their ideas to the next level.
            </p>
            <div className="pt-5 ">
              <SingleClick
                buttonClass="9"
                buttonText="Register"
                buttonType="button"
                onClick={handleClickRegister}
              />
            </div>
          </div>

          <div className="w-5/6 sm:w-3/4  xl:pb-14">
            <img
              src="/campusHero.svg"
              alt="Whta Campus"
              className="w-full lg:w-auto h-auto   sm:p-5 lg:px-28 "
            />
          </div>
        </div>
      </div>
      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed top-0 left-0  w-full h-full bg-black backdrop-filter backdrop-blur-sm bg-opacity-30 inset-0 z-30   flex items-center justify-center">
          {/* <div className="relative bg-white mt-18 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[] md:w-[25rem] md:h-[] h-auto md:max-w-[28rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[]  rounded-lg max-h-[80vh]    overflow-y-auto"> */}
          {renderStep()}
          {/* </div> */}
        </div>
      )}
    </div>
  );
}

export default HeroSection;
