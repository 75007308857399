import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Input = ({
  placeholder,
  type,
  inputClass,
  isRequired = false,
  inputValue,
  onChange
}) => {

  const { isValid, setIsValid } = useState(false);
  const { err, setErr } = useState("");

  const handleChange = (event) => {
    
    value(event.target.value);
  };

  const handleValidations = () => {
    if (type === "shortText") {
      let validReg = /^[a-zA-Z\s.']$/;
      setIsValid(validReg.test(inputValue));
      if (!validReg.test(inputValue)) {
        setErr("Enter your name");
      } else {
        setErr("");
      }
    } else if (type === "email") {

      let validReg = /^[a-zA-Z0-9._-]+@[a-zA]+.[a-zA-Z]$/;
      setIsValid(validReg.test(inputValue));
      if (!validReg.test(inputValue)) {
        setErr("Invalid email address");
      } else {
        setErr("");
      }
    } else if (type === "tel") {
      let validReg = /^\+?(\d{1,3})\s?(\d{1,11})$/
      setIsValid(validReg.test(inputValue));
      if (!validReg.test(inputValue)) {
        setErr("Enter a number");
      } else {
        setErr("");
      }
    }

    // add iany custom validation
  };
  // input class validation
  if (inputClass === "1") {
    inputClass =
      "w-full h-16 md:h-12 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none px-4 py-2";
  } else if (inputClass === "2") {
    inputClass =
      "w-6/12 h-16 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none";
  } else if (inputClass === "3") {
    inputClass = "w-4/12 h-16 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none";
  }else if (inputClass === "4") {
    inputClass = "flex items-center size-6 bg-white rounded-lg border-2 border-[rgba(120,14,78,0.5)] focus:border-[rgba(120,14,78,1)] focus:outline-none text-[rgba(120,14,78,1)] peer-checked:bg-[rgba(120,14,78,1)]"
  }
  // add more styles
  if ( isRequired == true && {handleChange} && inputValue === "") {
    return err;
  } else {
    inputValue === inputValue ;
  }

  return (
    <>
      <div className="relative">
        {err == "" ? "" : <p className="text-red-500">{err}</p>}

        <input
          required={isRequired}
          type={type}
          placeholder={placeholder}
          className={inputClass}
          value={inputValue}
          onChange={(e) => onChange(e.target.value)}
          onClick={handleValidations}
        />
      </div>
    </>
  );
};
Input.propTypes = {
  type: PropTypes.oneOf(["email", "number", "shortText"]),
  inputClass: PropTypes.oneOf(["1", "2", "3", "4"]),
  onClick: PropTypes.oneOf(["handleValidations"]),
  
  isRequired: PropTypes.bool,
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  
};
export default Input;
