import React from 'react';
import { Software } from '../../../../micros/utilities/dataUtilSoftware';
import { ServiceCard } from '../../../../micros/index'; 

function SoftwareComponent({data}) {
  const services = data; 

  return (
    <>
      <div id='learn_more' className='text-center flex flex-col justify-center items-center  pb-[4rem] sm:pb-[6rem] xl:pb-[10rem]'style={{ backgroundColor: "rgba(242, 225, 241, 0.7)" }}>
        <h1 className='font-poppins-bold  text-2xl md:text-5xl pt-[5rem] lg:pt-[9rem] text-purple-darker'>
          Software Solutions Process with wHTa Networks <span className='whitespace-nowrap'>for your Organization</span>
        </h1>
        <div className='w-[20rem] xl:w-[30rem] h-2 bg-purple-darker mt-4 mb-[3rem] lg:mb-[5.5rem]'>
        </div>
        <div className='flex justify-center items-center px-4 overflow-auto'>
          <div className='flex gap-[3rem] lg:gap-[5rem] items-center justify-start md:flex-row flex-col'>
            {services.map((service, index) => (
              <ServiceCard {...service} key={index} cardClass='1' titleClass='1' />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SoftwareComponent;


