import React from 'react'
import { WhyChooseUs } from "../../../micros/index";

function WhyWeb() {
    const whyusData = [
        {
            title: "IT Expertise",
            description:"We're ready to turn your ideas into cutting-edge solutions with our deep IT Expertise.",
            icon: "icons/brain.svg", 
          },  
        {
            title: "Transparency",
            description:"We openly share our processes, providing insight into client collaboration",
            icon: "icons/softdev.svg",
          },
        {
            title: "24/7 IT Support", 
            description: "Our team ensures constant system operation and proactive monitoring 24/7.",
            icon: "icons/headset1.svg",
          },
        {
            title: "Pricing",
            description:"Exceptional Quality, Friendly Prices - Your Ideal Software Development Partner",
            icon: "icons/money.svg",
          },
      ];
    
      return (
        <div className="w-full bg-purple-f">
          <h2 className="text-black-a text-4xl sm:text-5xl font-poppins-bold text-center py-10 sm:py-20 sm:pt-32">
            Why choose us
          </h2>
          <div className='flex items-center justify-center'>
            <div className="flex flex-wrap items-center justify-center lg:justify-between gap-4 pb-[4rem] sm:pb-[6rem] lg:pb-[12rem]">
              {whyusData.map((whyus, index) => (
                <WhyChooseUs {...whyus} key={index} />
              ))}
            </div>
          </div>
          
        </div>
      );
}

export default WhyWeb