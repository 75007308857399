import React, { useState } from "react";
import SingleClick from "../../../micros/actions/SingleClick";
import CheckboxesCard from "../../../micros/BookmeetingForm/hero/CheckBooker";
import Modal from "../../../micros/GetQuoteForm/GetQuoteForm";

function Hero0() {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openPopup = () => {
    setPopupOpen(true); 
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="flex flex-col gap-6 sm:gap-8 lg:gap-10 w-full sm:4/7 lg:w-2/5">
      <h3 className="flex flex-col text-4xl xl:text-6xl font-poppins-bold gap-2 sm:gap-3 lg:gap-4">
        <span>Creating</span>
        <span className="text-purple-dark">Innovative</span>
        <span className="whitespace-nowrap">Tech Solutions</span>
      </h3>
      <div className="flex gap-6 sm:gap-8 lg:gap-10 whitespace-nowrap">
        {/* <SingleClick
          buttonClass="1"
          buttonText="Get Quote"
          buttonType="button"
          onClick={toggleModal}
        /> */}
        {showModal && <Modal showModal={showModal} closeModal={closeModal} />}
        <SingleClick
          buttonClass="9"
          buttonText="Kickstart Your Project"
          buttonType="button"
          onClick={openPopup}
        />
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed top-0 left-0  w-full h-full bg-black backdrop-filter backdrop-blur-sm bg-opacity-30 inset-0 z-30   flex items-center justify-center">
          <div className="bg-white mt-25 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[30rem] md:w-[25rem] md:h-[40rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[42rem]  rounded-lg max-h-[90vh]    overflow-y-auto">
            {/* Add your popup content here */}
            <CheckboxesCard onClose={closePopup} />
          </div>
        </div>
      )}
    </div>
  );
}
export default Hero0;
