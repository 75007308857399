import React, { useState, useEffect } from 'react';
import { AllTestimonials } from '../utilities/dataUtil';
import Testimony from './Testimony';
import SingleClick from '../actions/SingleClick';

const TestimonialsPart = () => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const handleNext = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex === AllTestimonials.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex === 0 ? AllTestimonials.length - 1 : prevIndex - 1));
  };

  // Auto-scrolling functionality
  useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 5000); // Auto-scroll after 5 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [currentCardIndex]); // Reset timer when the current card index changes

  return (
    <div className='flex flex-col h-auto' style={{ backgroundColor: 'rgba(248, 250, 255, 1)' }}>
      <div className='text-purple-darker font-poppins-semi-bold py-6 flex items-center text-xl md:text-[2.25rem] justify-center'>
        <h1>Testimonials</h1>
      </div>

      <div className='flex justify-center gap-5 md:gap-20 p-5 flex-col md:flex-row'>
        <div className='flex flex-col'>
          <p className='text-black p-4 text-lg md:text-[2rem] font-dm-sans-bold leading-tight'>
            DON’T <br /> TAKE OUR <br /> WORD.
          </p>
          <p className='text-gray-500 p-4 text-lg md:text-[2rem] font-poppins-bold leading-tight'>TAKE THEIRS...</p>
        </div>

        <div className='flex flex-row'>
          <Testimony testimonials={[AllTestimonials[currentCardIndex]]} />
        </div>
      </div>

      <div className='flex flex-row justify-center mb-6'>
        <SingleClick
          buttonClass='10'
          buttonText=''
          buttonType='submit'
          buttonImage={'/leftarrow.svg'}
          onClick={handlePrevious}
        />

        <SingleClick
          buttonClass='10'
          buttonText=''
          buttonType='submit'
          buttonImage={'/Rightarrow.svg'}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default TestimonialsPart;
