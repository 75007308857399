import React, { useState } from "react";
import { SingleClick, Input, TextArea } from "../index.jsx";
import emailjs from "@emailjs/browser";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { credentials } from "../../../auth.jsx";
import { config } from "../../../auth.jsx";

function Modal({ showModal, closeModal }) {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [service, setService] = useState("");
  const [specifications, setSpecifications] = useState("");

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    closeModal();
  };

  if (modal) {
    document.body.classList.add("overflow-y-hidden");
  } else {
    document.body.classList.remove("overflow-y-hidden");
  }

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      email,
      description:phoneNumber,
      name,
      company,
      service,
      specifications,
    };

    try {
      const response = await fetch(`${config.baseURL}/careers/quotes/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${credentials}`,
        },
        body: JSON.stringify(formData),
      });

      console.log("Here is your form data",formData)
      let da=JSON.stringify(formData)
      console.log("JSON",da)
      const data = await response.json();
      console.log('Success:', data);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      

      // Reset form fields
      setEmail('');
      setPhoneNumber('');
      setName('');
      setCompany('');
      setService('');
      setSpecifications('');
      toggleModal();

    } catch (error) {
      console.error('Error:', error);
    }
    setModal(!modal);
    // send data with emailjs
    // const serviceId = "service_i2t3886";
    // const templateId = "template_kbgpofr";
    // const publicKey = "ONqH6-Z93cZfem7Rj";

    // const templateParams = {
    //   from_name: name,
    //   from_email: email,
    //   to_name: "wHTa Networks",
    //   message: `Quote Request from Company: ${company}, Service: ${service}, Specifications: ${specifications}.`,
    // };

    // emailjs
    //   .send(serviceId, templateId, templateParams, publicKey)
    //   .then((res) => {
    //     console.log("Email sent successfully: ", res);
    //     setName("");
    //     setEmail("");
    //     setCompany("");
    //     setService("");
    //     setSpecifications("");
    //     toggleModal();
    //   })
      // .catch((err) => {
      //   console.error("Error sending email, ", err);
      // });
  };

  return (
    
    showModal && (
      <div className="bg-black-e backdrop-filter backdrop-blur-sm bg-opacity-30 fixed inset-0 z-50 md:px-auto px-4  overflow-y-auto h-full">
        <div className="bg-white-a  rounded w-full m-5 md:w-full max-w-3xl h-auto  md:h-[580px] mt-[20px] ml-auto mr-auto  flex-wrap items-center py-2 md:px-9 justify-center overflow-x-auto overflow-y-auto">
          <button
            className="top-0 right-0 float-right mt-4 md:ml-[680px] text-gray-500 "
            onClick={handleCloseModal}
          >
            <svg
              className="h-10 w-10 "
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <form
            onSubmit={handleSubmit}
            className="flex w-full flex-col justify-center place-content-center items-center overflow-y-auto "
          >
            <section className="w-full max-w-md mx-auto md:mt-auto p-2">
              <h3 className="text-black-a font-poppins-semi-bold text-3xl sm:text-4xl lg:text-5xl justify-center text-center">
                Get a Quote!
              </h3>
              <h6 className="text-xl font-poppins whitespace-normal text-black-e justify-center text-center  mt-2">
                Describe how we can help you!
              </h6>
              <div className="flex flex-col sm:flex-row justify-center w-full px-4 py-6 h-auto place-content-center">
                <div className="flex sm:justify-between flex-col gap-4 w-full">
                  <Input
                    type="email"
                    placeholder="email"
                    inputClass="1"
                    isRequired={true}
                    asteriskClass="1"
                    value={email}
                    onChange={setEmail}
                    copyPaste={true}
                  />
                  <PhoneInput
                    international
                    defaultCountry="KE"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className="w-full rounded-lg border bg-purple-f p-4 pl-10 sm:pl-12 focus:outline-none text-sm md:text-xl"
                    required
                  />
                  <Input
                    type="text"
                    placeholder="name"
                    inputClass="1"
                    isRequired={true}
                    asteriskClass="1"
                    value={name}
                    onChange={setName}
                    copyPaste={true}
                  />
                  <Input
                    type="text"
                    placeholder="company"
                    inputClass="1"
                    isRequired={true}
                    asteriskClass="1"
                    value={company}
                    onChange={setCompany}
                    copyPaste={true}
                  />
                  <Input
                    type="text"
                    placeholder="service"
                    inputClass="1"
                    isRequired={true}
                    asteriskClass="1"
                    value={service}
                    onChange={setService}
                    copyPaste={true}
                  />
                  <TextArea
                    placeholder="specifications"
                    rows={4}
                    cols={2}
                    textareaClass="1"
                    value={specifications}
                    onChange={setSpecifications}
                  />
                </div>
              </div>
              <div className="flex justify-center place-content-center items-center place-items-center pr-4 mt-8 md:mb-20 mb-4">
                <SingleClick
                  buttonClass="7"
                  buttonText="Send"
                  buttonType="submit"
                />
              </div>
              {modal && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
                  <div
                    onClick={toggleModal}
                    className="fixed inset-0 w-screen h-screen"
                  >
                    <div className="box sm:w-2/3 lg:w-1/2 xl:w-2/5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 leading-loose bg-white py-3.5 px-3.5 shadow shadow-slate-500 rounded max-w-600 md:min-w-300 md:w-full md:h-80 w-auto h-auto text-center">
                      <h2 className=" text-xl md:text-4xl font-poppins-bold mt-6 tracking-wider">
                        Message Sent!
                      </h2>
                      <p className="font-poppins text-md mt-4">
                        Our team shall contact you soon
                      </p>
                      <img
                        src="/icons/check.svg"
                        alt="check img"
                        className="w-15 h-16 mx-auto mt-6"
                      />
                      <button
                        className="absolute py-2 px-1.5 top-0 right-4 text-xl text-slate-400"
                        onClick={toggleModal}
                      >
                        x
                      </button>
                    </div>
                    <div></div>
                  </div>
                </div>
              )}
            </section>
          </form>
        </div>
      </div>
    )
  );
}

export default Modal;
