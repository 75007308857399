import React from 'react'
import { ServiceCard } from '../../../micros/index'

function PentestingService() {
    const services = [
        {
            title: 'Social Engineering Testing',
            description: "Simulating social engineering attacks, such as phishing and pretexting, to assess your employees' awareness and response to potential threats."
        },
        {
            title: 'Web Application Penetration Testing',
            description: 'Evaluating the security of your web applications, APIs, and databases to uncover vulnerabilities such as SQL injection, cross-site scripting (XSS), and authentication flaws'
        },
        {
            title: 'Network Penetration Testing',
            description: "Assessing the security of your network infrastructure, including routers, switches, firewalls, and servers, to identify potential entry points for attackers."
        },
        {
            title:'Mobile Penetration Testing',
            description: "Testing the security of your mobile applications on iOS and Android platforms to identify vulnerabilities and secure sensitive data."
        },
        {
            title:"Wireless Network Penetration Testing",
            description: "Assessing the security of your wireless networks and devices to identify vulnerabilities such as weak encryption protocols and unauthorized access points."
        }


    ]
  return (
    <>
        <div id="learn_more"className=' text-center flex flex-col justify-center items-center bg-purple-f pb-[4rem] sm:pb-[6rem] xl:pb-[10rem]'>
            <h1 className='font-poppins-bold text-3xl sm:text-4xl xl:text-5xl pt-[5rem] lg:pt-[9rem] text-purple-darker'>Our Penetration Testing Services</h1>
            <div className='w-[20rem] xl:w-[35rem] h-2 bg-purple-darker mt-4 mb-[3rem] lg:mb-[8.5rem]'></div>
            <div className='flex justify-center items-center px-4'>
                <div className='flex gap-[3rem] lg:gap-[5rem] items-center flex-wrap justify-center  md:flex-row flex-col'>
                    {services.map((service, index) => (
                       <ServiceCard {...service} key={index} cardClass='2' tittleClass='2'/>

                        )
                    )}
                </div>
         
            </div>
            
        </div>
    </>
  )
}

export default PentestingService