import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";

const TextArea = forwardRef(
  ({ placeholder, rows, cols, textareaClass, value, onChange }, ref) => {
    const [isValid, setIsValid] = useState(false);
    const [err, setErr] = useState("");

    const handleChange = (event) => {
      onChange(event.target.value);
      handleValidation(event.target.value);
    };

    const handleValidation = (value) => {
      // allow alphabets and numbers while excluding special characters
      let validRegex = /^[a-zA-Z0-9_-\s].{5,}$/;
      setIsValid(validRegex.test(value));
      if (!validRegex.test(value)) {
        setErr("Must not be less than 5 characters long");
      } else {
        setErr("");
      }
    };

    if (textareaClass === "1") {
      textareaClass =
        " w-full h-full rounded-lg border border-purple-darker bg-purple-f p-2 pt-12 pl-10 focus:outline-none text-xl border-opacity-20";
    }
    // add more styles using if else..
    else if (textareaClass === "2") {
      textareaClass =
        " w-full rounded-md border border-purple-darker bg-purple-f p-2  pl-4 focus:outline-none text-sm border-opacity-20";
    } else if (textareaClass === "3") {
      textareaClass =
        " w-full rounded-md border border-purple-darker bg-purple-f p-2 pb-8  pl-4 focus:outline-none text-[9pt] text-gray-800 border-opacity-20";
    }

    return (
      <div className="relative w-full">
        {err === "" ? "" : <p className="text-red-600 ">{err}</p>}

        <textarea
          required
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          className={textareaClass}
          value={value}
          onChange={handleChange}
          ref={ref}
        />
        {!isValid && (
          <div
            className={
              textareaClass === "2"
                ? "absolute  left-0 pl-[6px] pt-[6px] flex items-start pointer-events-none"
                : "absolute inset-y-5 left-0 pl-8 pt-6 flex items-start pointer-events-none"
            }
          >
            <span className="text-red-500 hidden">*</span>
          </div>
        )}
      </div>
    );
  }
);

TextArea.propTypes = {
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  textareaClass: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;
