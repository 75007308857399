import React from 'react'

// custom list
function ListLink({ title, data }) {
  return (
    <>
        <div className='p-2'>
            <h3 className="text-sm font-dm-sans-bold text-black">{title}</h3>
            <ul>
            {data.map((item, index) => (
                <li className="text-base font-dm-sans text-black-d hover:text-[#FD00E4] overflow-wrap"
                style={{ 
                    whiteSpace: 'pre-wrap', 
                    maxWidth: '100%',
                    wordBreak: 'break-word' // Allow long words to break and wrap
                }}
                 key={index}>
                {item.link ? (
                    <a href={item.link} target='_blank' className="flex flex-row  ">
                    {item.icon && (
                        <img
                        className="w-4 h-4 mr-2"
                        src={item.icon}
                        alt={item.alt}
                        />
                    )}
                    {item.text}
                    </a>
                ) : (
                    item.text
                )}
                </li>
            ))}
            </ul>
        </div>

    </>
  )
}

export default ListLink