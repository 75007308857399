import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Input = forwardRef(
  (
    {
      placeholder,
      type,
      inputClass,
      asteriskClass,
      isRequired = false,
      value,
      onChange,
      copyPaste = false,
      onInput,
    },
    ref
  ) => {
    const [err, setErr] = React.useState("");

    const handleValidation = () => {
      if (type === "email") {
        let validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!validRegex.test(value)) {
          setErr("Invalid email address");
        } else {
          setErr("");
        }
      } else if (type === "text") {
        let validRegex = /^[a-zA-Z\s.,!?'"-]{3,100}$/;
        if (!validRegex.test(value)) {
          setErr(
            "Must be 3 to 20 characters long with no numbers or special characters"
          );
        } else {
          setErr("");
        }
      } else if (type === "textalpha") {
        let validRegex = /^[a-zA-Z0-9\s-/#]{3,100}$/;
        if (!validRegex.test(value)) {
          setErr(
            "Must be 3 to 20 characters long with no numbers or special characters"
          );
        } else {
          setErr("");
        }
      } else if (type === "password") {
        let validRegex =
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,]).{8,16}$/;
        if (!validRegex.test(value)) {
          setErr(
            "Password must contain at least one digit, one lowercase letter, one uppercase letter, one symbol, and be 8 to 16 characters long"
          );
        } else {
          setErr("");
        }
      } else if (type === "textarea1") {
        let validRegex = /^[a-zA-Z\s.,!?'"-]{10,140}$/;
        if (!validRegex.test(value)) {
          setErr(
            "Must be 10 to 120 characters long with no numbers or special characters"
          );
        } else {
          setErr("");
        }
      } else if (type === "textarea2") {
        let validRegex = /^[a-zA-Z\s.,!?'"-]{10,140}$/;
        if (!validRegex.test(value)) {
          setErr(
            "Must be 10 to 140 characters long with no numbers or special characters"
          );
        } else {
          setErr("");
        }
      } else if (type === "linkedin") {
        let validRegex =
          /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|profile)\/[a-zA-Z0-9_-]+\/?$/;
        if (!validRegex.test(value)) {
          setErr("Invalid LinkedIn profile URL");
        } else {
          setErr("");
        }
      } else if (type === "github") {
        let validRegex =
          /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9_-]+\/?$/;
        if (!validRegex.test(value)) {
          setErr("Invalid GitHub profile URL");
        } else {
          setErr("");
        }
      } else if (type === "address") {
        // Valid regex for addresses, allowing letters, numbers, spaces, and common punctuation
        let validRegex = /^[a-zA-Z0-9\s.,-]{1,140}$/;

        if (!validRegex.test(value)) {
          setErr(
            "Address must be 1 to 140 characters long and can only contain letters, numbers, spaces, commas, periods, and hyphens."
          );
        } else {
          setErr("");
        }
      } else if (type === "studentemail") {
        let validRegex =
          /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)?(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)[a-zA-Z0-9.-]+\.([a-zA-Z0-9.-]{2,}\.)?[a-zA-Z]{2,}$/;
        // /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.)?(jkuat\.edu)$/;
        // /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!validRegex.test(value)) {
          setErr(
            "Please use your school email address (e.g., example@myschool.edu)."
          );
        } else {
          setErr("");
        }
      } else if (type === "year") {
        let validRegex = /^[0-9]{4}$/;
        if (!validRegex.test(value)) {
          // setErr("Please enter a valid year (e.g., 2023).");
        } else {
          setErr("");
        }
      }
    };

    const handleCopy = (event) => {
      if (copyPaste === true) {
        event.preventDefault();
      }
    };

    // Asterisk styling
    if (asteriskClass === "1") {
      asteriskClass =
        "absolute inset-y-0 left-0 pl-8 sm:pl-10 flex items-center pointer-events-none";
    }

    // Input styles
    if (inputClass === "1") {
      inputClass =
        "w-full rounded-lg border border-purple-darker bg-purple-f p-4 pl-10 sm:pl-12  focus:outline-none text-sm md:text-xl border-opacity-20";
    } else if (inputClass === "2") {
      inputClass =
        "w-full rounded-sm border border-purple-darker border-opacity-10 bg-purple-f p-2 pl-10 sm:pl-12  focus:outline-none text-xl ";
    } else if (inputClass === "3") {
      inputClass =
        "w-full rounded-sm border border-purple-darker border-opacity-10 bg-purple-f p-2 pl-1.5 sm:pl-2.5 focus:outline-none text-sm";
      placeholder = `*${placeholder}`;
    } else if (inputClass === "4") {
      inputClass =
        "w-full rounded-sm border border-purple-darker border-opacity-10 bg-purple-f p-2 pl-1.5 sm:pl-2.5 focus:outline-none text-sm pb-20 max-w-[400px]";
    } else if (inputClass === "5") {
      inputClass =
        "w-full rounded-lg border border-purple-darker border-opacity-10 bg-purple-f px-2 py-4 pl-1.5 sm:pl-2.5 mb-3 focus:outline-none text-sm";
    }

    return (
      <div className="relative">
        {err && <p className="text-red-600 text-wrap">{err}</p>}
        {type === "checkbox" ? (
          <input
            ref={ref}
            required={isRequired}
            type={type}
            className={inputClass}
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
          />
        ) : type === "file" ? (
          <input
            ref={ref}
            required={isRequired}
            type={type}
            className={inputClass}
            onChange={(e) => onChange(e.target.files[0])}
            onBlur={handleValidation}
            onCopy={handleCopy}
            onPaste={handleCopy}
            onInput={onInput}
          />
        ) : (
          <input
            ref={ref}
            required={isRequired}
            type={type}
            placeholder={placeholder}
            className={inputClass}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={handleValidation}
            onCopy={handleCopy}
            onPaste={handleCopy}
            onInput={onInput}
          />
        )}
        {!err && isRequired && type !== "checkbox" && (
          <div className={asteriskClass}>
            <span className="text-red-500">*</span>
          </div>
        )}
      </div>
    );
  }
);

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    "email",
    "text",
    "textalpha",
    "linkedin",
    "textarea1",
    "textarea2",
    "github",
    "checkbox",
    "file",
    "studentemail",
    "year",
  ]).isRequired,
  inputClass: PropTypes.oneOf(["1", "2", "3", "4", "5"]),
  asteriskClass: PropTypes.string,
  isRequired: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
  copyPaste: PropTypes.bool,
  onInput: PropTypes.func,
};

export default Input;
