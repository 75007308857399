/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { AvailableJobs, Jobcards, ContactUs } from "../../components/index.jsx";

function Careers() {
  const [filteredJobList, setFilteredJobListings] = useState([]);
  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>
      <div className="mb-10">
        <AvailableJobs setFilteredJobListings={setFilteredJobListings} />
      </div>
      {/* <Jobcards/> */}

      {/* <ContactUs /> */}
    </div>
  );
}

export default Careers;
