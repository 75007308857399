import React, { useState } from "react";
import { HalfHeroSection, SingleClick } from "../../../../micros";
import Modal from "../../../../micros/GetQuoteForm/GetQuoteForm";
import { CheckboxesCard } from "../../../../micros";
function IndustriesHerosection({maintitle,backgroundimage,title}) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const scrollToItem = () => {
    const item = document.getElementById("learn_more");
    if (item) {
      item.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    //Content for the first section

    <div className="flex font-poppins bg-gray-50 flex-col h-auto min-h-screen pt-20 w-full p-auto overflow-y-hidden"
    //  style={{backgroundImage: 'url(/ServiceHeroman.svg)',
    //  height: '100%',
    //  width: '100%',
    //  backgroundSize: 'cover',
    //  backgroundPosition: 'center',}}
    >
          {/* <img src={backgroundimage} alt='img' className="bg-cover h-full w-full bg-center min-h-[8rem]" /> */}
          <HalfHeroSection backgroundImage={backgroundimage} text={title}/>
          
          <div className="w-full flex flex-col p-5" >
            <h1 className="font-bold md:text-5xl text-3xl text-center text-purple-darker p-2">{maintitle}</h1>
            <p className="text-center md:text-xl font-poppins-light text-xl justify-center p-2">wHTa networks is a premier provider of software and cyber security solutions,understands the industry's pulse and craft strategies that<br/> enhance your digital security and perfomance</p>
          </div>
     
          <div className="flex p-7 flex-col gap-2   w-full justify-center items-center">
            <div className="w-fit  ">
          <h1 className="font-bold text-xl text-left px-10 text-purple-darker md:text-3xl p-4">Transform your digital landscape with Our Expertise</h1>
          <div className="flex flex-row text-lg"><img src="/tick.svg" alt="tick" className="px-3 text-left justify-start"/> Immersive Software Solutions</div>
          <div className="flex flex-row text-lg"><img src="/tick.svg" alt="tick" className="px-3 text-left justify-start"/> AI-driven Threat Detection</div>
          <div className="flex flex-row text-lg"><img src="/tick.svg" alt="tick" className="px-3 text-left justify-start"/> Client Engagement</div>
          <div className="flex flex-row text-lg"><img src="/tick.svg" alt="tick" className="px-3 text-left justify-start"/> Comprehensive Cybersecurity and Software Integration</div>
          </div>
          <div className="pt-5 md:px-12 ">
                 
                <SingleClick
                    buttonClass="1"
                    buttonText="Learn More"
                    buttonType="button"
                    onClick={scrollToItem}
                />
            </div> 

          </div>
        
          {/* <div className="flex w-full h-full mb-4 lg:mb-8 py-4  justify-center items-center">
            <div className="p-10 bg-purple-100 w-fit flex flex-col items-center text-center">

           <p>Ready to elevate your digital </p>
           <p> Security and perfomance</p>
            <div className="pt-5 ">
                 
                <SingleClick
                    buttonClass="9"
                    buttonText="Book Meeting"
                    buttonType="button"
                    onClick={openPopup}
                />
            </div>
          </div>
      </div> */}
      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed top-0 left-0  w-full h-full bg-black backdrop-filter backdrop-blur-sm bg-opacity-30 inset-0 z-30   flex items-center justify-center">
          <div className="bg-white mt-32 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[30rem] md:w-[25rem] md:h-[40rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[42rem]  rounded-lg max-h-[80vh]    overflow-y-auto">
            {/* Add your popup content here */}
            <CheckboxesCard onClose={closePopup} />
          </div>
        </div>
      )}
    </div>
  );
}

export default IndustriesHerosection;
