import React from 'react';
import { SingleClick } from '../../../micros';

function CourseCard({ courses }) {
    const handleBrochureDownload = (courseId) => {
        // Perform any additional actions here, e.g., tracking the download
        console.log(`Downloading brochure for course ${courseId}`);
    };

    return (
        <div className='p-4 h-auto flex flex-wrap items-start justify-center'>
            {courses.map((course, index) => (
                <div key={index} className='mb-8 md:mr-4 mr-0 p-2 md:p-3'>
                    {/* Card */}
                    <div className='relative bg-white-a rounded-xl w-[15rem] h-[30rem] md:w-[18rem] md:h-[35rem] flex flex-col shadow-xl'>
                        <div className='relative h-1/2'>

                            <div className="absolute top-10 left-0 bg-red-500 text-white text-xs font-bold py-2 px-4 rounded-br-lg z-10">
                                10% OFF
                            </div>
                            
                            <img src={course.image} alt={`Course ${index + 1} Image`} className='w-full h-full rounded-xl' />
                        </div>
                        <div className='flex-grow px-5 md:px-10 py-2 bg-white'>
                            <p className='text-[1rem] font-quicksand-bold mb-2'>{course.title}</p>
                            <div className='flex w-full justify-center mt-2'>
                                <SingleClick
                                    buttonClass="20"
                                    buttonText={"Download Brochure"}
                                    buttonType="button"
                                    fileUrl={course.brochureUrl}
                                    onClick={() => handleBrochureDownload(course.brochureUrl)}
                                />
                            </div>
                            {/* <p className='text-[0.725rem] font-poppins mb-1'>Skills you’ll gain:</p>
                            <p className='text-[0.625rem] font-poppins mb-4'>{course.skills}</p> */}
                        </div>
                        <div className='flex flex-row justify-between items-center px-5 md:px-8 py-2 text-[0.75rem]'>
                            <div className='flex items-center'>
                                <img src={course.profileIcon} alt={`Profile Icon ${index + 1}`} className='p-1 rounded-full mr-2' style={{ borderRadius: '20px' }} />
                                <p>{course.academy}</p>
                            </div>
                            <p>{course.price}</p>
                        </div>
                        <div className='flex flex-col pb-5'>
                            <div className='flex flex-row justify-between px-5 md:px-10 py-2 font-poppins text-[0.625rem] text-black-e'>
                                <p>{course.level}</p>
                                <p>{course.certificate}</p>
                            </div>
                            <div className='flex w-full justify-center mt-2'>
                                <SingleClick
                                    buttonClass="18"
                                    buttonText={"Enroll now"}
                                    buttonType="submit"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CourseCard;