import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import Header from "../../../micros/ItConsultingDetails/Header";
import Description from "../../../micros/ItConsultingDetails/Description";
import Details from "../../../micros/ItConsultingDetails/Details";

function ItConsulting() {
  const itConsultationDetails = [
    {
      title: "IT Infrastructure Management",
      description: [
        "Our IT Infrastructure Management services are tailored to meet your unique business needs. We focus on keeping your systems secure, reliable, and ready to scale as your business grows.",
        "By proactively managing your hardware, software, and network resources, we minimize downtime and ensure everything runs smoothly",
        "This allows you to focus on what you do best, while we handle the technical complexities behind the scenes. With our support, you can confidently pursue your goals, knowing your IT infrastructure is in expert hands.",
      ],
      details: [
        {
          name: "Assessment and Planning",
          image: "/mindMap.svg",
          detailsDescription: [
            "Evaluate the current IT infrastructure and identify business requirements.",
            "Develop a strategic plan that aligns IT resources with business goals and future growth.",
          ],
        },
        {
          name: "Design and Implementation",
          image: "/laptopSettings.svg",
          detailsDescription: [
            "Create a detailed design for the IT infrastructure, including network, servers, and security.",
            "Select and deploy the necessary technology, configuring systems to meet specific needs.",
          ],
        },
        {
          name: "Monitoring and Management",
          image: "/performanceMacbook.svg",
          detailsDescription: [
            "Continuously monitor performance, availability, and security across the infrastructure.",
            "Perform regular maintenance, updates, and provide technical support to ensure smooth operations.",
          ],
        },
        {
          name: "Scalling and Optimization",
          image: "/scales.svg",
          detailsDescription: [
            "Plan for capacity and scale the infrastructure as business needs evolve.",
            "Optimize systems and resources for improved efficiency and cost-effectiveness.",
          ],
        },
        {
          name: "Backup and Disaster Recovery",
          image: "/cloudBackupRestore.svg",
          detailsDescription: [
            "Regularly back up critical data and develop a disaster recovery plan.",
            "Test and update the plan to ensure rapid recovery in case of system failure or data loss.",
          ],
        },
      ],
    },
    {
      title: "Cybersecurity and Risk Management",
      description: [
        "Our Cybersecurity and Risk Management services are designed to safeguard your business against evolving threats. We assess your current security posture, identify vulnerabilities, and implement robust defenses to protect your sensitive data.",
        "Our team continuously monitors and adapts to new risks, ensuring your cybersecurity strategy evolves with the threat landscape.",
        "With proactive risk management, we help you minimize potential impacts and maintain business continuity, giving you peace of mind in an increasingly digital world.",
      ],
      details: [
        {
          name: "Risk Assessment",
          image: "/mindMap.svg",
          detailsDescription: [
            "We start by performing a detailed risk assessment to uncover any vulnerabilities and threats specific to your organization.",
            "By identifying weaknesses and prioritizing them based on their impact, we ensure that we address the most critical areas first, setting the stage for effective security improvements.",
          ],
        },
        {
          name: "Security Strategy Development",
          image: "/laptopSettings.svg",
          detailsDescription: [
            "After assessing your risks, we craft a customized security strategy tailored to your needs. This strategy includes developing comprehensive security policies and selecting the right technologies to safeguard your business.",
            "Our goal is to align this strategy with your organizational objectives, creating a robust framework that adapts to evolving threats and provides you with peace of mind.",
          ],
        },
        {
          name: "Implementation of Controls",
          image: "/performanceMacbook.svg",
          detailsDescription: [
            "This includes installing essential security tools such as firewalls and intrusion detection systems, and integrating them seamlessly with your existing infrastructure.",
            "Our team ensures that these measures are set up correctly and are fully operational, all while minimizing any disruption to your daily operations.",
          ],
        },
        {
          name: "Continuous Monitoring and Management",
          image: "/scales.svg",
          detailsDescription: [
            "This involves real-time threat detection and regular updates to your security systems.",
            "Our proactive approach ensures that we can swiftly address any potential issues before they escalate, keeping your defenses robust and up-to-date.",
          ],
        },
        {
          name: "Incident Response and Recovery",
          image: "/cloudBackupRestore.svg",
          detailsDescription: [
            "We have procedures in place for containing and resolving breaches, and for recovering your systems with minimal downtime.",
            "After handling the incident, we conduct a thorough analysis to prevent future occurrences, enhancing your overall security strategy.",
          ],
        },
      ],
    },
    {
      title: "Incident Response and Planning",
      description: [
        " We create detailed, customized response plans tailored to your organization's needs, providing clear protocols for rapid identification and resolution of incidents.",
        "Our expert team is dedicated to minimizing downtime and reducing the impact of security breaches through proactive strategies and real-time support.",
        "With our comprehensive approach, you gain peace of mind knowing that your organization is equipped to handle any security threat effectively. Trust wHTa to fortify your defenses and safeguard your operations.",
      ],
      details: [
        {
          name: "Preparation",
          image: "/mindMap.svg",
          detailsDescription: [
            " Establishing security policies, training staff, and setting up necessary tools and resources for effective incident response.",
          ],
        },
        {
          name: "Identification",
          image: "/laptopSettings.svg",
          detailsDescription: [
            "Quickly detect and analyze security threats to understand their nature and scope. This phase is crucial for accurate assessment and timely action.",
          ],
        },
        {
          name: "Containment",
          image: "/performanceMacbook.svg",
          detailsDescription: [
            "Implement strategies to isolate the threat and limit its impact, preventing further damage and maintaining operational stability.",
          ],
        },
        {
          name: "Eradication",
          image: "/scales.svg",
          detailsDescription: [
            "Remove the root cause of the incident and address vulnerabilities to prevent recurrence. Ensure that all aspects of the threat are thoroughly resolved.",
          ],
        },
        {
          name: "Recovery",
          image: "/cloudBackupRestore.svg",
          detailsDescription: [
            "Restore affected systems to normal operations while monitoring for any lingering issues. Conduct a detailed review to refine future incident response strategies.",
          ],
        },
      ],
    },
    {
      title: "Social Media Investigations",
      description: [
        "Our Social Media Investigations service ensures thorough and effective analysis of online activities. We start by meticulously collecting relevant data from various platforms while adhering to legal and ethical standards.",
        "Our experts then analyze and verify this data to uncover critical insights and patterns.",
        "Finally, we compile comprehensive reports and provide actionable recommendations to support your decision-making and address any identified issues.",
      ],
      details: [
        {
          name: "Collection",
          image: "/mindMap.svg",
          detailsDescription: [
            " Gather relevant data from social media platforms, including posts, messages, and user interactions. Ensure compliance with legal and ethical standards during data collection.",
          ],
        },
        {
          name: "Analysis",
          image: "/laptopSettings.svg",
          detailsDescription: [
            "Examine the collected data to identify patterns, connections, and potential evidence related to the investigation. Utilize advanced tools and techniques to extract actionable insights.",
          ],
        },
        {
          name: "Verification",
          image: "/performanceMacbook.svg",
          detailsDescription: [
            " Confirm the authenticity and relevance of the data. Cross-check information to ensure accuracy and credibility, reducing the risk of misinformation.",
          ],
        },
        {
          name: "Reporting",
          image: "/scales.svg",
          detailsDescription: [
            "Compile findings into comprehensive reports that detail the investigation's results. Present evidence clearly and effectively to support decision-making and legal proceedings.",
          ],
        },
        {
          name: "Action",
          image: "/cloudBackupRestore.svg",
          detailsDescription: [
            " Implement measures based on the investigation's findings. Use insights to address issues, enhance security, or support legal actions as necessary.",
          ],
        },
      ],
    },
  ];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [services, setServices] = useState(itConsultationDetails);
  const titleTabs = services.map((item) => item.title);
  const [tabs, setTabs] = useState(titleTabs);
  const [activeTab, setActiveTab] = useState(services[0].title);
  const [activeService, setActiveService] = useState(services[0]);

  useEffect(() => {
    // Extract the query string without a key
    const queryString = location.search.substring(1); // Remove leading '?'
    if (queryString) {
      const tabFromUrl = queryString.replace(/-/g, " ");
      handleClickTab(tabFromUrl);
    }
  }, [location.search]);

  const handleClickTab = (tab) => {
    setActiveTab(tab);
    const activatedService = services.find((item) => item.title === tab);
    setActiveService(activatedService);
  };
  return (
    <div className="mt-14">
      <Header
        activeTab={activeTab}
        handleClickTab={handleClickTab}
        tabs={tabs}
      />
      <Description
        activeServiceTitle={activeService.title}
        activeServiceDescription={activeService.description}
      />
      <Details activeServiceDetails={activeService.details} />
    </div>
  );
}

export default ItConsulting;
