import React, { useState, useRef } from "react";
import { Input, SingleClick } from "../../../micros";

function StepTwo({ onNext, closePopup, formData, updateFormData }) {
  const [checkboxOneChecked, setCheckboxOneChecked] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [filesUploaded, setFilesUploaded] = useState({
    student_id: false,
    selfie_with_id: false,
  });
  const [uploadedFiles, setUploadedFiles] = useState({
    student_id: "",
    selfie_with_id: "",
  });

  // refs for the inputs
  const studentIdInputRef = useRef(null);
  const selfieInputRef = useRef(null);

  const handleCheckboxOneChange = (value) => {
    setCheckboxOneChecked(value);
  };
  const handleFileChange = (id, file) => {
    if (file) {
      // Check if the file is a .jpeg
      if (file.type !== "image/jpeg") {
        setUploadError(`Only .jpeg/.jpg files are allowed for ${id}.`);
        setFilesUploaded((prev) => ({ ...prev, [id]: false }));
        setUploadedFiles((prev) => ({ ...prev, [id]: "" }));
        return;
      }

      updateFormData({ [id]: file });
      setFilesUploaded((prev) => ({ ...prev, [id]: true }));
      setUploadedFiles((prev) => ({ ...prev, [id]: file.name }));

      console.log(formData);
    } else {
      setFilesUploaded((prev) => ({ ...prev, [id]: false }));
      setUploadedFiles((prev) => ({ ...prev, [id]: "" }));
    }
  };

  const handleDivClick = (inputRef) => {
    inputRef.current.click();
  };

  const saveData = () => {
    if (!filesUploaded.student_id || !filesUploaded.selfie_with_id) {
      setUploadError("Please upload both your student ID and selfie with ID.");
      return;
    }
    setUploadError("");

    console.log(formData);
    console.log(formData.student_id.name);
    onNext();
  };
  return (
    <div className="relative bg-white mt-18 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[] md:w-[25rem] md:h-[] h-auto md:max-w-[28rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[]  rounded-lg max-h-[80vh]    overflow-y-auto">
      <div className="relative font-poppins pb-6">
        <p
          className="absolute flex justify-center items-center cursor-pointer  right-0 top-0 rounded-full text-center z-100"
          onClick={closePopup}
        >
          {/* <img src="/cancel.svg" alt="" className="size-4" /> */}
          <img src="/close.svg" alt="" className="size-4" />
        </p>
        <div className="px-6 pt-10">
          <h2 className="text-2xl text-gray-800 font-poppins-semi-bold mb-3">
            2. Step Two
          </h2>
          {/* Registerartion form */}
          <form action="" className="">
            <h2 className="text-xl font-poppins-semi-bold text-[#05195F] mb-2">
              Verify our Identity
            </h2>
            <label htmlFor="studentid" className="">
              Students ID
            </label>
            <p className="my-2 text-xs">
              Ensure the document is clear and all the four corners are visible.
            </p>
            <div className="hidden">
              <Input
                type="file"
                id="student_id"
                ref={studentIdInputRef}
                // className=""
                inputClass="1"
                onChange={(file) => handleFileChange("student_id", file)}
              />
            </div>
            <div
              className="cursor-pointer flex flex-col items-center w-[65%] mb-4 text-center border border-gray-400 rounded-md py-2 mr-2"
              onClick={() => handleDivClick(studentIdInputRef)}
            >
              {!filesUploaded.student_id ? (
                <>
                  <div className="w-[100px] h-[65px] bg-[url('/upload1.svg')] bg-center bg-no-repeat bg-contain">
                    {/* <img src="/upload1.svg" alt="upload image" className="w-14" /> */}
                  </div>
                  <p>Upload Students ID</p>
                  <p className="text-xs my-1">
                    .jpeg and should be more than 500kb
                  </p>
                </>
              ) : (
                <p className="text-xs px-2">{uploadedFiles.student_id}</p>
              )}
            </div>
            <label htmlFor="selfie" className="">
              Selfie with your ID
            </label>
            <p className="text-xs my-2">
              Ensure the document is clear and it does not cover your face.
            </p>
            <div className="hidden">
              <Input
                type="file"
                id="selfie_with_id"
                ref={selfieInputRef}
                inputClass="1"
                onChange={(file) => handleFileChange("selfie_with_id", file)}
                onInput={() => setUploadError("")}
              />
            </div>
            <div
              className="cursor-pointer flex items-center justify-between w-full mt-3 h-[]"
              onClick={() => handleDivClick(selfieInputRef)}
            >
              <div className="flex flex-col items-center w-[65%] text-center border border-gray-400 rounded-md py-2 mr-2">
                {!filesUploaded.selfie_with_id ? (
                  <>
                    <div className="w-[100px] h-[65px] bg-[url('/upload1.svg')] bg-center bg-no-repeat bg-contain">
                      {/* <img
                        src="/upload1.svg"
                        alt="upload image"
                        className="w-[100%] h-[100%] border border-red-400"
                      /> */}
                    </div>
                    <p>Upload Document</p>
                    <p className="text-xs my-1">
                      .jpeg and should be more than 500kb
                    </p>
                  </>
                ) : (
                  <p className="text-xs px-2">{uploadedFiles.selfie_with_id}</p>
                )}
              </div>
              {!filesUploaded.selfie_with_id && (
                <div className="h-[130px]">
                  <img
                    src="/selfie.svg"
                    alt="selfie with ID"
                    className=" h-[100%]"
                  />
                </div>
              )}
            </div>
            {uploadError !== "" && (
              <p className="text-red-900 text-xs mt-2">{uploadError}</p>
            )}
            <div className="flex gap-2 text-xs items-center my-3">
              <Input
                type="checkbox"
                id="graduation"
                inputClass="5"
                value={checkboxOneChecked}
                onChange={(value) => handleCheckboxOneChange(value)}
                onInput={() => setUploadError("")}
              />
              <p>
                I have read the{" "}
                <a href="" className="text-[#E917BB]">
                  Privacy Policy
                </a>
                , and I agree to the{" "}
                <a href="" className="text-[#E917BB]">
                  Terms of service
                </a>
              </p>
            </div>
            <div className="mt-6 w-full flex justify-center">
              <SingleClick
                buttonClass="10"
                buttonText="Upload and Continue"
                buttonType="button"
                onClick={saveData}
                disabled={!checkboxOneChecked}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StepTwo;
