import React from 'react';

const HalfHeroSection = ({ backgroundImage, text }) => {
  return (
    <div className="relative flex flex-col items-center justify-center">
      {/* semi-transparent dark overlay */}
      <div className="relative">
        <img src={backgroundImage} alt={backgroundImage} className="bg-cover bg-center min-h-[8rem]" />
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>
      <h1 className="text-xl lg:text-[2.5rem] xl:text-[3rem] text-white font-poppins-bold sm:p-5 lg:p-[4.5rem] absolute text-center z-10 whitespace-nowrap" style={{ top: '70%', left: '50%', transform: 'translate(-50%, -50%)' }}>{text}</h1>
    </div>
  );
};

export default HalfHeroSection;
