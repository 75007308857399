import React, { useState } from "react";
import { CheckboxesCard, SingleClick } from "../../../micros/index";
import Modal from "../../../micros/GetQuoteForm/GetQuoteForm";
import SolutionCard from "../../../micros/service/SolutionCard";

function SoftwareService() {
  const cardData = [
    {
      icon: "icons/custom.svg",
      title: "Custom Websites",
      description:
        "We craft unique, responsive, and user-friendly websites that effectively represent your brand.",
    },
    {
      icon: "icons/brain.svg",
      title: "AI Chat-bots",
      description:
        "We develop chatbots that provide personalized support, automate tasks, and improve overall user experience on your digital platforms",
    },
    {
      icon: "icons/web.svg",
      title: "Web-based Apps",
      description:
        "Our solutions offer seamless user experiences and robust functionalities tailored to meet your specific needs.",
    },
    {
      icon: "icons/cyber.svg",
      title: "Mobile Apps",
      description:
        "Our mobile app development services ensure high performance, intuitive interfaces, and cross-platform compatibility to keep you connected with your audience anytime, anywhere.",
    },
  ];
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div id='learn_more' className="flex flex-col lg:flex-row items-center justify-center bg-purple-f p-[2rem] pt-[80px] pl-[50px] sm:pb-[6rem] mx-0 w-full min-h-screen">
        <div className="flex flex-col gap-2 sm:gap-6 lg:gap-4 w-full sm:w-4/7 lg:w-2/5 text-left">
          <div className="flex flex-col">
            <h3 className="text-purple-normal-active text-[40px] font-quicksand-bold">
              <span className="text-purple-dark block">
                Software Solutions for
              </span>
              <span className="text-purple-dark block mt-[-1.0rem]">
                your business!
              </span>
            </h3>
          </div>
          <div className="flex flex-col">
            <span className="text-black-a font-poppins">
              Our Team is Dedicated to Providing Quality Services
            </span>
            <span className="text-black-a font-poppins">
              that meet your Business needs.
            </span>
          </div>
          <div className="flex gap-2 sm:gap-6 lg:gap-8 whitespace-nowrap">
            {showModal && (
              <Modal showModal={showModal} closeModal={closeModal} />
            )}
            <SingleClick
              buttonClass="9"
              buttonText="Kickstart Your Project"
              buttonType="button"
              onClick={openPopup}
            />
          </div>
          {/* Popup */}
          {isPopupOpen && (
            <div className="fixed top-0 left-0 w-full h-full bg-black backdrop-filter backdrop-blur-sm bg-opacity-30 inset-0 z-30 flex items-center justify-center">
              <div className="bg-white mt-25 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[30rem] md:w-[25rem] md:h-[40rem] lg:w-[30rem] xl:w-[32rem] xl:h-[42rem] rounded-lg max-h-[90vh] overflow-y-auto">
                <CheckboxesCard onClose={closePopup} />
              </div>
            </div>
          )}
        </div>

        <div className="flex-1 flex flex-wrap gap-3 justify-center items-stretch lg:w-3/5">
          {cardData.map((card, index) => (
            <SolutionCard
              key={index}
              icon={card.icon}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default SoftwareService;
