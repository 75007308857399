import React, { useState } from "react";
import { SingleClick, CheckboxesCard } from "../index";

function ServiceFeatures({
  title,
  price,
  mainFeatures,
  extraFeatures,
  colorFill,
}) {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <section className="group transition-all duration-700 w-[19rem] hover:w-[22rem] sm:w-[20rem] sm:hover:w-[24rem] h-[36rem] hover:h-[40rem] rounded-tr-3xl">
        <div className="h-14 flex items-center justify-center bg-white-a ">
          <h4 className="text-base group-hover:text-lg">{title}</h4>
        </div>
        <div
          className={`bg-${colorFill} h-full flex flex-col justify-around rounded-b-xl opacity-70 group-hover:opacity-100 text-white-a transition-all duration-700`}
        >
          <div>
            <p className="font-poppins text-sm">
              A package and service from us would cost this
            </p>
            <h1 className="font-poppins-semi-bold text-[2rem] group-hover:text-[2.5rem] duration-500">
              $ {price}
            </h1>
          </div>

          <div>
            {mainFeatures.map((feature, index) => (
              <p
                key={index}
                className="flex flex-row items-center text-start justify-start text-sm group-hover:text-base gap-4 p-2 pl-8 font-poppins-semi-bold duration-500"
              >
                <span>
                  <img src="/icons/isChecked.svg" alt="img" />
                </span>{" "}
                {feature}
              </p>
            ))}
            <div className="w-full bg-white-a h-0.5 my-2"></div>
            {extraFeatures.map((feature, index) => (
              <p
                key={index}
                className="flex flex-row items-center  justify-start text-sm group-hover:text-base gap-4 p-2 pl-8 text-start font-poppins-semi-bold duration-500"
              >
                <span>
                  <img src="/icons/isChecked.svg" alt="img" />
                </span>{" "}
                {feature}
              </p>
            ))}
          </div>
          <div className="">
            <SingleClick
              buttonClass="11"
              buttonText="Book Meeting"
              buttonType="button"
              onClick={openPopup}
            />
          </div>
        </div>
        {isPopupOpen && (
          <div className="fixed top-0 left-0  w-full h-full bg-black backdrop-filter backdrop-blur-sm bg-opacity-30 inset-0 z-30   flex items-center justify-center">
            <div className="bg-white mt-32 items-center flex-wrap justify-center overflow-x-hidden p-2 mx-8 sm:w-auto sm:h-[30rem] md:w-[25rem] md:h-[40rem] lg:w-[30rem]  xl:w-[32rem] xl:h-[42rem]  rounded-lg max-h-[80vh]    overflow-y-auto">
              {/* Add your popup content here */}
              <CheckboxesCard onClose={closePopup} />
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default ServiceFeatures;
