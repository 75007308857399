import React from "react";
import { CoursesOffered, WhatWeDo, Whyus } from "../../../major/index.jsx";

function CourseSection() {
  return (
    <div className="bg-purple-f  px-4 sm:px-8 w-full h-auto flex flex-col justify-center items-center ">
      {/*<CoursesOffered />*/}
      <WhatWeDo />
      <Whyus />
    </div>
  );
}

export default CourseSection;
