import React from 'react';

import AcademyHero from '../../major/academyPage/hero/AcademyHero';
import BrowseSection from './browseAcademy/BrowseAcademy';
const AcademyPage = () => {
  return (

  <div>
      <div className=' flex flex-col items-center justify-center' >
        <AcademyHero/>
        <BrowseSection/>
      </div>
    </div>)}
export default AcademyPage;
