import React from "react";
import { ServicesHero } from "../../../components/index";
import { WhyItConsulting } from "../../../components/major/index";
import { BannerSection } from "../../../components/micros/index";
import ItHero from "../../../components/sections/servicePage/hero/ItHero";
import { ItConsultingDetails } from "../../../components/major/index";
import { ClassNames } from "@emotion/react";

function ItConsulting() {
  return (
    <div className="font-poppins">
      <ItHero />
      <ItConsultingDetails />
      <div className="px-24">
        <WhyItConsulting />
      </div>
    </div>
  );
}

export default ItConsulting;
