import React, { useState } from "react";

function Whocanjoin() {
  const whocanjoinquizes = [
    {
      question: "Who is eligible to join the wHTa Networks Campus Hub?",
      answer:
        "The wHTa Networks Campus Hub is open to students enrolled in an educational institution. Prospective members must provide their student information, including a valid student email and ID, to verify their enrollment.",
    },
    {
      question: "What types of identification are required for registration?",
      answer:
        "To verify identity, applicants must upload a Student ID that clearly shows their full name and photo. Additionally, they must provide a selfie with their ID for further verification.",
    },
    {
      question:
        "Are there any fees associated with joining the wHTa Networks Campus Hub?",
      answer:
        "Yes, there is a non-refundable registration fee of KES 1,800, which must be paid as part of the final step in the registration process.",
    },
    {
      question:
        "What information do applicants need to provide about their educational background?",
      answer:
        "Applicants need to provide their full name, student email, phone number, registration number, course enrolled, name of the institution, faculty/school, and department. This detailed information helps confirm their status as students or recent graduates.",
    },
    {
      question: "Can recent graduates join the wHTa Networks Campus Hub?",
      answer:
        "Yes, recent graduates are eligible to join the wHTa Networks Campus Hub. The registration form includes fields for 'Year Enrolled' and 'Graduation Year,' indicating that both current students and recent graduates can apply.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuizClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="flex flex-col gap-4 mt-4 md:mt-16 mb-10">
      <div className="flex flex-col items-center sm:justify-center sm:gap-4 mb-4">
        <h1 className="text-[#9B1F96] text-2xl sm:text-4xl font-poppins-semi-bold">
          Who can join us?
        </h1>
      </div>
      <div className="px-10 sm:px-20 flex flex-col gap-[14px]">
        {whocanjoinquizes.map((quiz, index) => (
          <div key={index} className="">
            <div
              className="flex cursor-pointer max-w-[800px]"
              onClick={() => handleQuizClick(index)}
            >
              <div className="flex justify-center items-center p-[2px] w-6 h-6 border-2 border-gray-600 rounded-full">
                {activeIndex === index ? (
                  <div className="relative top-[-2px] text-gray-600 text-4xl px-2">
                    -
                  </div>
                ) : (
                  <div className="text-gray-600 text-2xl">+</div>
                )}
              </div>

              <div className="flex flex-col ml-2 font-poppins">
                <div className="mt-[-4px]">
                  <p className="text-gray-600 md:text-lg hover:underline">
                    {quiz.question}
                  </p>
                </div>
                {activeIndex === index && (
                  <div className="">
                    <p className="text-purple-600 text-sm">{quiz.answer}</p>
                  </div>
                )}
                {activeIndex === index && (
                  <hr className="w-full h-[2px] bg-gray-400 mb-4" />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Whocanjoin;
