import React from 'react'
import {
  ServicesHero,
 
} from '../../../components/index'
import { WhyPentesting, PentestingService } from '../../../components/major/index'
import { BannerSection } from '../../../components/micros/index'



function Pentesting() {
  
  return (
    <>
      <ServicesHero
        serviceTitle='Penetration Testing' 
        serviceTitleImg='/icons/Forensics.png' 
        serviceDescription1='Our elite team of cybersecurity experts employs advanced penetration testing techniques to uncover and address potential security gaps in your systems. We simulate real-world cyber attacks to ensure your business is fortified against evolving threats.' 
        serviceDescription2='Safeguard your data, protect your reputation, and stay compliant with our comprehensive penetration testing services.' 
        serviceImg='/icons/pentestingImg.svg' 
      />
      
      <WhyPentesting />
      <div className='flex items-center justify-center '>
        <BannerSection
          backgroundImage='/icons/pentestBanner1.png'
          desc='"Protect your products with thorough testing: "Quality is never an accident; it is always the result of intelligent effort." - John Ruskin"'
          btnText='Book a Meeting'

        />
      </div>

      <PentestingService />
      
    </>
  )
}

export default Pentesting