// src/services/studentApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from './authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: 'http://localhost:8000/api',
    // prepareHeaders: (headers) => {
    //     const token = sessionStorage.getItem('accessToken');
    //     if (token) {
    //         headers.set('Authorization', `Bearer ${token}`);
    //     }
    //     return headers;
    // },
});


export const signInApi = createApi({
    reducerPath: 'signInApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getCourses: builder.query({
            query: () => '/elearning/courses/',
        }),
        
    
    
        preSignUp: builder.mutation({
            query: (formData) => ({
                url:'/payments/make-payment/',
                method:'POST',
                payload:formData,
            }),
        }),
        checkPaypalPayment: builder.mutation({
            query: (formData) => ({
                url:'/payments/paypal/execute/',
                method:'POST',
                payload:formData,
            }),
        }),
        getMpesaPaymentStatus: builder.query({
            query: (id) => `/payments/mpesa/status/${id}`
        }),
        registerUser: builder.mutation({
            query: (formData) => ({
                url:'/register/',
                method:'POST',
                payload:formData,
            }),
        }),

        updateProfile: builder.mutation({
            query: (formData) => ({
                url:'/profile/',
                method:'PUT',
                payload:formData,
            }),
        }),
       


    }),
    
});

export const {  useGetCoursesQuery ,
                usePreSignUpMutation,
                useCheckPaypalPaymentMutation,
                useGetMpesaPaymentStatusQuery,
                useRegisterUserMutation,
                useUpdateProfileMutation,
            } = signInApi;
