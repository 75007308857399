import React from 'react'
import { EventPage } from '../../components/major/index'
const Event = () => {
  return (

  <>
  <EventPage/>
  </>
    
  )
}

export default Event