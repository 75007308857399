import React from "react";
import SingleClick from "../../../micros/actions/SingleClick";

function AboutText() {
  return (
    <>
      <div className="w-full sm:w-1/2 flex flex-col justify-between h-auto text-2xl font-dm-sans-semi-bold">
        <h3 className="text-purple-darker text-3xl sm:text-4xl lg:text-5xl text-center sm:text-start font-poppins-semibold mb-4 lg:mt-6">
          About wHTa
        </h3>
        <div>
          <p className="text-black text-center sm:text-start font-dm-sans-semi-bold text-xl sm:text-2xl">
            wHTa is a dynamic software development and cybersecurity agency
            dedicated to crafting innovative solutions. We specialize in
            creating robust, scalable software applications while prioritizing
            security at every step.
          </p>

          <br />
          <p className="text-black text-center sm:text-start font-dm-sans-semi-bold text-xl sm:text-2xl">
            Our team of skilled developers and cybersecurity experts
            collaborates closely with clients to deliver tailored, cutting-edge
            solutions that exceed expectations.
          </p>
        </div>

        <div className="flex justify-center sm:justify-start mt-6 lg:mb-6">
          {/*<SingleClick buttonClass="1" buttonText="C.S.R" buttonType="button" /> */}
        </div>
      </div>
    </>
  );
}

export default AboutText;
