import React from 'react'
import {
  ServicesHero,

} from '../../../components/index'
import { WhyDigForensics, ForensicService } from '../../../components/major/index'
import { BannerSection } from '../../../components/micros/index'

function DigitalForensics() {
  return (
    <>
      <ServicesHero
        serviceTitle='Digital Forensics' 
        serviceTitleImg='/icons/Forensics.png' 
        serviceDescription1='When your organization faces a cyber threat or security breach, swift and effective response is critical to minimizing damage, protecting sensitive data, and restoring operations.' 
        serviceDescription2="That's where our digital forensics and incident response services come in. "
        serviceImg='/icons/digital foorensics.svg' 
      />

      <WhyDigForensics />
      <div className='flex items-center justify-center '>
        <BannerSection
          backgroundImage='/icons/pentestBanner1.png'
          desc='Unlocking the secrets of the digital realm, one bit at a time.'
          btnText='Book a Meeting'

        />
      </div>

      <ForensicService />
    </>
  )
}

export default DigitalForensics