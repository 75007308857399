import React, { useState } from "react";
import { SingleClick } from "../../../micros";
import MeetingForm from "./Bookform";
import MeetingFormCompany from "./BookformCompany";

const CheckboxesCard = ({ onClose, onContinue }) => {
  const [isChecked1, setChecked1] = useState(true);
  const [continueClicked, setContinueClicked] = useState(false);

  const handleContinueClick = () => {
    // Set the state to indicate that the "Continue" button has been clicked
    setContinueClicked(true);
    // Additional logic or actions on continue, if needed
    onContinue = () => {
      //if (isChecked1 ) {
      //  return <h1>IndividualMeeting </h1>;
      //} else {
      //  return <h1>OOO</h1>;
      //}
    };
  };
  return (
    <div className="max-w-screen-md mx-auto xl:w-[30rem] lg:w-[28rem]  bg-white xl:h-[58rem] md:w-[23rem]  md:h-[35rem] sm:w-auto sm:h-[30rem]  p-2 rounded-lg  ">
      {continueClicked ? (
        // Render the replacement component based on the checked card
        isChecked1 ? (
          <div className="flex items-center">
            <MeetingForm onClose={onClose} />
          </div>
        ) : (
          <div className="flex items-center">
            <MeetingFormCompany onClose={onClose} />
          </div>
        )
      ) : (
        // Render the checkboxes card if "Continue" has not been clicked
        <>
          <div
            className="top-0 right-0 p-2 m-2 mt-2 mr-2 float-right text-gray-600 cursor-pointer"
            onClick={onClose}
          >
            <svg
              className="h-6 w-6 md:h-10 md:w-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="text-center mt-12 p-5 ">
            <p className="xl:text-[2rem] lg:text-[2rem] md:text-[1.5rem] sm:text-[1rem] font-poppins-semi-bold whitespace-nowrap">
             Lets Kickstart Your Project!
            </p>
          </div>

          <form className="mt-4 flex flex-col items-center w-full ">
            {/*first card */}
            <div
              className={`icon-input p-4 mt-4 flex rounded-xl w-full text-center  xl:p-8 lg:p-8 md:p-8 sm:p-2 ${
                isChecked1
                  ? "bg-purple-300 border border-purple-700"
                  : "bg-gray-200 border border-gray-600"
              } focus:outline-none items-center justify-center`}
              checked={isChecked1}
              onClick={() => setChecked1(!isChecked1)}
            >
              <div>
                <div className="flex flex-row w-fit  items-center ">
                  <img src="/house icon.svg" alt="house_icon" />

                  <div className="flex flex-col items-center justify-center xl:p-4 lg:p-4 md:p-4 sm:p-2 xl:px-5 lg:px-5 sm:px-2  ml-2 ">
                    <p className="xl:text-lg lg:text-lg  font-poppins-semi-bold w-full text-start sm:text-sm  ">
                      As an Individual
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*Second card */}
            <div
              className={`icon-input p-4 mt-4 flex rounded-xl text-center w-full xl:p-6 lg:p-6 md:p-6 sm:p-2  ${
                isChecked1
                  ? "bg-gray-200 border border-gray-600"
                  : " bg-purple-300 border border-purple-700"
              } focus:outline-none items-center justify-center`}
              checked={!isChecked1}
              onClick={() => setChecked1(!isChecked1)}
            >
              <div className="flex flex-row w-fit items-center ">
                <img src="/pig_icon.svg" alt="house_icon" />

                <div className="flex flex-col items-center justify-center xl:p-6 lg:p-6 xl:px-4 lg:px-4 sm:px-2 sm:p-2 ml-2">
                  <p className="xl:text-lg lg:text-lg  font-poppins-semi-bold w-full text-start sm:text-sm ">
                    As an Organisation
                  </p>
                </div>
              </div>
            </div>
          </form>

          <div className="flex items-center justify-center pt-10 pb-10 ">
            <SingleClick
              buttonClass="10"
              buttonText="Book"
              buttonType="submit"
              onClick={handleContinueClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CheckboxesCard;
