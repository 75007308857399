import React from 'react';
import { HalfHeroSection } from '../../../micros';

const AcademyHero = () => {
    
  return (

  <div className="w-full  max-w-8xl max-h-[59rem] mt-24">
     <HalfHeroSection backgroundImage='/academy.svg' text='Academy' />
     
     
    </div>
    )}

   
export default AcademyHero;
