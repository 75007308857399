import React, { useState, useEffect } from "react";
import { HalfHeroSection } from "../../components/micros/index.jsx";
import { ApplicationSection } from "../../components/micros/index.jsx";
import { ApplicationBanner } from "../../components/micros/index.jsx";

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts or updates
  }, []);
  const [filteredJobList, setFilteredJobListings] = useState([]);

  // technical and soft skills
  const technicalSkills = [
    "HTML",
    "CSS",
    "JavaScript",
    "React.js",
    "Angular",
    "Vue.js",
    "SASS",
    "LESS",
    "Git Hub",
  ];
  const softSkills = [
    "Problem-Solving",
    "Communication",
    "Collaboration",
    "Time management",
    "Organization",
    "Attention to detail",
    "Adaptability",
    "Creativity",
    "Commitment to quality",
  ];

  // responsibilities and qualifications
  const responsibilities = [
    "Developing and maintaining web applications (web development).",
    "Writing client-side scripts for enhanced functionality (scripting).",
    "Ensuring website performance optimization (optimization).",
    "Conducting thorough testing and debugging of frontend elements (testing).",
    "Managing version control systems (version control).",
    "Documenting processes and sharing knowledge within the team (documentation).",
  ];

  const qualifications = [
    "Bachelor's degree in Computer Science or related field (or equivalent experience).",
    "Previous experience as a frontend developer with a portfolio project.",
    "Certifications or additional training in frontend development are a plus.",
  ];

  return (
    <div className="mt-24">
      <div>
        <HalfHeroSection
          backgroundImage={"/icons/careerPNG.png"}
          text={"Careers"}
        />
      </div>

      <div>
        <ApplicationSection
          Title={"Frontend Developer"}
          Description={
            "We are currently looking for a skilled Frontend Developer to join our team. The ideal candidate will be proficient in HTML, CSS, and JavaScript, with hands-on experience in frameworks such as React.js, Angular, or Vue.js. Additionally, knowledge of CSS preprocessors and responsive design is required."
          }
          technicalSkills={technicalSkills}
          softSkills={softSkills}
          responsibilities={responsibilities}
          qualifications={qualifications}
        />
      </div>

      <div className="flex items-center justify-center ">
        <ApplicationBanner
          backgroundImage="/icons/applicationbanner.png"
          desc={
            <>
              "The best way to get started is to quit talking and begin doing."
              <br />
              {"- walt disney"}
            </>
          }
          btnText="Apply Now"
        />
      </div>
    </div>
  );
}

export default Application;
