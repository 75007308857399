import React, { useState } from "react";
import { SingleClick } from "../../micros";
import ApplyPopUp from "./ApplyPopUp";

function ApplicationBanner({ backgroundImage, desc, btnText = null }) {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="relative flex flex-col items-center justify-center max-w-8xl">
      <p className="leading-tight text-[1.2rem] sm:text-[1.5rem] lg:text-[2rem] xl:text-[2.5rem] text-white italic sm:p-5 absolute text-center z-10 line-clamp-3">
        {desc}
      </p>
      {btnText && (
        <div className="absolute z-10 bottom-1 lg:bottom-6 xl:bottom-12">
          <SingleClick
            buttonClass="9"
            buttonText={btnText}
            buttonType="button"
            onClick={openPopup}
          />
        </div>
      )}

      <div className="relative">
        <img
          src={backgroundImage}
          alt={backgroundImage}
          className="bg-cover bg-center min-h-[9rem]"
        />
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>
      {isPopupOpen && <ApplyPopUp onClose={closePopup} />}
    </div>
  );
}

export default ApplicationBanner;
